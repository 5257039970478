
/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
 import axios from 'axios';
  
 // 环境的切换
 if (process.env.NODE_ENV == 'development') {

     // axios.defaults.baseURL = 'http://192.168.2.113:5100/life-app';
     axios.defaults.baseURL = 'https://www.sdlivingculture.com/life-app';
 } else if (process.env.NODE_ENV == 'debug') {    
     // axios.defaults.baseURL = 'http://192.168.2.113:5100/life-app';
     axios.defaults.baseURL = 'https://www.sdlivingculture.com/life-app';
 } else if (process.env.NODE_ENV == 'production') {    
     // axios.defaults.baseURL = 'http://192.168.2.113:5100/life-app';
     axios.defaults.baseURL = 'https://www.sdlivingculture.com/life-app';
 }
  
 // 请求超时时间
 axios.defaults.timeout = 10000;
  
 // post请求头
//  application/json
 axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
  
 // 请求拦截器
 axios.interceptors.request.use(    
     config => {
        let languageType =1
         // languageType 1:中文简体2:英文3:中文繁体
         if(localStorage.getItem('language')=='zh'){
            languageType=1
         } 
         if(localStorage.getItem('language')=='TW'){
            languageType=3
         }
         if(localStorage.getItem('language')=='En'){
            languageType=2
         }      
         languageType && (config.headers.languageType = languageType);        
         return config;    
     },    
     error => {        
         return Promise.error(error);    
     })
  
 // 响应拦截器
 axios.interceptors.response.use(    
     response => {        
         if (response.status === 200) {            
             return Promise.resolve(response);        
         } else {            
             return Promise.reject(response);        
         }    
     },
     // 服务器状态码不是200的情况    
     error => {        
         if (error.response.status) {            
             switch (error.response.status) {
                 // 404请求不存在                
                 case 404:        
                    this.$message({
                        message: this.$t('requery.网络请求不存在'),
                        type: 'error'
                      });               
                 break;                
                 // 其他错误，直接抛出错误提示                
                 default:      
                    this.$message({
                        message: error.response.data.message,
                        type: 'error'
                      });           
             }            
             return Promise.reject(error.response);        
         }       
     }
 );
 /** 
  * get方法，对应get请求 
  * @param {String} url [请求的url地址] 
  * @param {Object} data [请求时携带的参数] 
  */
 export function get({url, data}){    
     return new Promise((resolve, reject) =>{        
         axios.get(url, data)        
         .then(res => {            
             resolve(res.data);        
         })        
         .catch(err => {            
             reject(err.data)        
         })    
     });
 }
 /** 
  * post方法，对应post请求 
  * @param {String} url [请求的url地址] 
  * @param {Object} data [请求时携带的参数] 
  */
 export function post({url, data}) {    
     return new Promise((resolve, reject) => {         
         axios.post(url, data)        
         .then(res => {            
             resolve(res.data);        
         })        
         .catch(err => {            
             reject(err.data)        
         })    
     });
 }
 