// 简体技能提升
export default {
    requery:{
        网络请求不存在:'网络请求不存在',

    },
    tophead: {
      商城:"商城",
      名校直通车:"名校直通车",
      学生公寓:'学生公寓',
      港漂干货:'港漂干货',
      成功案例:'成功案例',
      首页:"首页",
      背景提升:"背景提升",//修改
      香港留学:"香港留学",
      院校入口:"院校入口",
      关于我们:"关于我们",
      语言提升:'语言提升',
      学术:'学术',
      副学士:'副学士',
      本科:'本科',
      研究生:'研究生',
      香港文化科技有限公司:'（香港）文化科技有限公司',//修改
      深圳文化科技有限公司:'（深圳）文化科技有限公司',//修改
      汇生会:'汇生活',
      香港:'香港',
      深圳:'深圳',
      文化科技有限公司:'文化科技有限公司',
      我是标题:'我是标题'
    },
    footer:{
        加入我们:"加入我们",
        联系电话:'联系电话',
        地址:'地址',
        小红书:'小红书',
        公众号:'公众号',
        咨询号:'咨询号',
        汇生会:'汇生活',//修改
        香港:'香港',
        深圳:'深圳',
        文化科技有限公司:'文化科技有限公司',

    },
    homepage:{//首页
        图片:'@/assets/image/homepage/zw/x1.png',
        留学申请计划:'留学申请计划',
        副学士:'（副学士/本科/研究生/博士）',
        留学文书创作:'留学文书创作',
        为您提供有效的学习规划:'为您提供有效的学习规划',
        留学面试辅导:'留学面试辅导',
        帮您逐一攻克面试流程:'帮您逐一攻克面试流程',
        背景提升室:'背景提升室',
        全方位提高竞争力:'全方位提高竞争力',
        名校直通车:'名校直通车',
        学校直录:'学校直录，圆梦名校',
        香港学生公寓:'香港学生公寓',
        给你一个不一样的家:'给你一个不一样的家',
        服务精选:"服务精选",
        HK汇生会:'HK汇生会',
        支持:"支持/合作机构",
        Serviceselection:'SERVICES',
        Strengthes:'STRENGTHES',
        APPLICATIONPROCESS:'PARTNERS',

    },
    background:{//背景提升
        IELTS:'IELTS',
        TOEFL:'TOEFL',
        GRE:'GRE',
        EMET:'GMAT',
        实习项目:'实习项目',
        科研项目:'科研项目',
        求职辅导:'求职辅导',
        论文辅导:'论文辅导',
        课程辅导:'课程辅导',
        留学面试辅导:'留学面试辅导',
        提示类型:'提升类型',
        PromptType:"Prompt Type",

    },
    overseasStudy:{//香港留学
        联系我们:'联系我们',
        全套申请计划:'全套申请计划',
        直通车申请计划:'直通车申请计划',
        半DIY申请计划:'半DIY申请计划',
        适合人群:'适合人群',
        项目特色:'项目特色',
        留学咨询:"留学咨询",
        没有留学申请经验:'没有留学申请经验，需要时间规划和申请辅助',
        没有确定申请学校:'没有确定申请学校，需要学校信息和选校分析',
        英语语言能力不足:'英语语言能力不足，不能与招生官无障碍沟通',
        想要提升背景:"想要提升背景，冲刺申请顶级名校",
        想要有专业老师把控整个申请流程:'想要有专业老师把控整个申请流程',
        想有导师全程规划考试及选课流程:'想有导师全程规划考试及选课流程',
        除求学外:'除求学外，还有留美实习工作需求',
        留学工作全指导:'留学工作全指导，根据需求保证就业',
        没量身打造独特背景提升增加竞争优势:'没量身打造独特背景提升增加竞争优势',
        中美两国咨询老师全程把关助力申请:'中美两国咨询老师全程把关助力申请',
        已完成标准化考试 :"已完成标准化考试，时间和精力充足",
        初步确定目标学校:'初步确定目标学校，了解其申请要求',
        无法独立完成文书:'无法独立完成文书，需要指导与修改',
        希望获得专业指导:'希望获得专业指导，提高录取成功率',
        专业咨询老师评价学生背景:'专业咨询老师评价学生背景，提供选校建议',
        专业文书老师指导创作思路:'专业文书老师指导创作思路，修改申请文书',
        可灵活选择签注指导:'可灵活选择签注指导、行前辅导等多项服务',
        详细的留学规划:'详细的留学规划',
        基于企业内部庞大的数据库资料:"基于企业内部庞大的数据库资料，同时结合学生的学术背景、兴趣爱好和未来的发展规划，目标院校及专业，专业的课程设置等进行最合适的选校定位规划。",
        全方位的背景提升:'全方位的背景提升',
        根据选校定位为学生匹配相关的背景提升项目和机会:'根据选校定位为学生匹配相关的背景提升项目和机会，包括大厂实习、科研项目、游学项目等，全方位提升学生的整体竞争力。',
        优质的文书材料:'优质的文书材料',
        文书顾问与学生进行头脑风暴:"文书顾问与学生进行头脑风暴，一对一沟通，针对不同专业定制化挖掘学生亮点，从招生官角度撰写个性化高定版的文书材料，拒绝模板化及千篇一律。",
        高效的笔试面试特训:'高效笔试面试特训',
        HK汇生会拥有丰富的本科:'HK汇生会拥有丰富的本科、硕士、博士笔试面试 辅导经验，由港澳专业名师指导，提供全真模拟面试及专业详细的点评。',
        升学后的留学援助:'升学后的留学援助',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题，提供的学术辅导服务让学生能够顺 利完成留学阶段的学习任务。",
        申请前:'申请前',
        Beforeapplication:"Before application",
        选校定位:'选校定位',
        选校分析:'选校分析',
        方案定位:'方案定位',
        留学策略定制:'留学策略定制',
        个月VIP定制私人留学顾问:'12个月VIP定制私人留学顾问',
        名师实时咨询:'名师实时咨询',
        选校方案定位:'选校方案定位',
        申请策略定制:'申请策略定制',
        申请材料指导:'申请材料指导',
        网申课程全覆盖:'网申课程全覆盖',
        申请中:'申请中',
        Inapplication:'In application',
        文书材料:'文书材料',
        全套文书:'全套文书',
        CV:'CV',
        RS:"RS",
        R2:'R2',
        PCE:'PCE',
        文书翻译:'文书翻译',
        高定文书:'高定文书',
        颜色:'颜色',
        网申助手:'网申助手',
        网申管家:'网申管家',
        面试指导:'面试指导',
        申请后:'申请后',
        application:'application',
        签证指导:'签证指导',
        落地服务:'落地服务',
        海外租房:'海外租房',
        留学迎新礼包:'留学迎新礼包',
        香港本土高端留学机:"香港本土高端留学机构",//修改
        年高端申请经验:"15年高端申请经验",
        海量名校录取案例:'海量名校录取案例',
        服务模式:"5+1服务模式",
        位老师服务:'5位老师服务1名学生',
        包括香港本土老师和名校顾问:'包括香港本土老师和名校顾问',
        对申请结果负责:'对申请结果负责',
        录取成功率:"95%录取成功率",//修改
        录取率:"90%目标名校录取率",//修改
        一站式留学服务:"一站式留学服务",
        提供完善的接机:'提供完善的签证指导、住宿安排、学术辅导、实习求职规划等服务',
        学生姓名:'学生姓名',
        申请类型:'申请类型',
        学术成绩:'学术成绩',
        英语成绩:'英语成绩',
        录取大学:'录取大学',
        查看更多:'查看更多',
        Advantages:'ADVANTAGES',
        留学优势:'留学优势',
        Study:'PROGRAMS',
        选择适合你的留学计划:'选择适合你的留学计划',
        SERVICE:'SERVICE SYSTEM',
        科学智能的服务体系:"科学智能的服务体系，全面提升你的留学战斗力",
        Prepare:'SERVICES',
        准备信息:'准备信息',
        汇生汇留学服务:'HK汇生会留学服务，帮你逐一攻克申请流程关键点',//修改
        PROCESS:'PROCESS KEY POINTS',
        ADVANTAGE:'ADVANTAGE',
        选择汇生汇:'选择汇生会，让香港丰富导师资源为你所用',//修改
        录取捷报:'录取捷报',
        SUCCESSOFFER:"OFFER",
        APPLICATION:'APPLICATION PROCESS',
        支持:'支持/合作机构',
        多种留学方案匹配不同人群:'多种留学方案匹配不同人群',
        对选校定位及留学申请流程不清楚:"对选校定位及留学申请流程不清楚",//新增
        缺少时间精力自行准备申请材料:'缺少时间精力自行准备申请材料',//新增
        根据学生条件提供定制式留学方案:'根据学生条件提供定制式留学方案',//新增
        提供准确的申请时间规划:'提供准确的申请时间规划',//新增
        提供详细全面的申请材料指导:'提供详细全面的申请材料指导',//新增
        根据相关专业和面试要求提供面试服务和指引材料:"根据相关专业和面试要求提供面试服务和指引材料",//新增
        本科院校背景较差:'本科院校背景较差',//新增
        GPA及语言成绩偏低:'GPA及语言成绩偏低',//新增
        软实力不足:'软实力不足，缺乏实践经验',//新增
        国内考研失利后想入读名校:'国内考研失利后想入读名校',//新增
        从前期咨询到成功入学全程一站式服务收获更高性价比:'从前期咨询到成功入学全程一站式服务收获更高性价比',//新增
        资深教育界专家顾问:'资深教育界专家顾问，一对一笔试面试专业指导',//新增
        提供独家资源:"提供独家资源，全程跟踪服务，保证最终学生顺利入学",//新增
        有明确的选校定位:"有明确的选校定位",//新增
        主动性较强且有清晰的申请规划:"主动性较强且有清晰的申请规划",//新增
        英语阅读及写作能力较强:'英语阅读及写作能力较强',//新增
        针对学生需求提供细分服务:'针对学生需求提供细分服务（包括文书服务，网申指导等）',//新增
        拒绝捆绑式销售:'拒绝捆绑式销售，高性价比',//新增
        有效沟通:'有效沟通，提供专业性指导',//新增
        During:"During application",//新增
        After:'After application',//新增
        个人陈述:"个人陈述（PS）",//新增
        简历:'简历（CV）',//新增
        推荐信:"推荐信（RL）",//新增
        其他:'其他（essay等）',//新增
        选择汇生会:"HK汇生会，助力学生斩获梦校录取",//新增
        年高端申请服务经验:"6年高端申请服务经验",//新增
        优质的定制化文书材料:'优质的定制化文书材料',//新增
        一站式服务流程:'资深留学服务团队',//新增
        专业资深导师团队:'专业资深导师团队',//新增
        包括香港本地教授助阵:'包括香港本地教授助阵',//新增
        海量成功案例:'海量成功案例',//新增
        升学后的全方位援助服务:'升学后的全方位援助服务',//新增
        提供专业的住宿服务:'提供专业的住宿服务、学术辅导及就业指导规划',//新增
        副学士:'副学士',
        本科:"本科",
        研究生:'研究生',
        副学士升学途径:"服务",
        },
        associatedegree:{//副學士
            联系我们:'联系我们',
            全套申请计划:'全套申请计划',
            直通车申请计划:'直通车申请计划',
            半DIY申请计划:'半DIY申请计划',
            适合人群:'适合人群',
            项目特色:'项目特色',
            留学咨询:"留学咨询",
            没有留学申请经验:'没有留学申请经验，需要时间规划和申请辅助',
            没有确定申请学校:'没有确定申请学校，需要学校信息和选校分析',
            英语语言能力不足:'英语语言能力不足，不能与招生官无障碍沟通',
            想要提升背景:"想要提升背景，冲刺申请顶级名校",
            想要有专业老师把控整个申请流程:'想要有专业老师把控整个申请流程',
            想有导师全程规划考试及选课流程:'想有导师全程规划考试及选课流程',
            除求学外:'除求学外，还有留美实习工作需求',
            留学工作全指导:'留学工作全指导，根据需求保证就业',
            没量身打造独特背景提升增加竞争优势:'没量身打造独特背景提升增加竞争优势',
            中美两国咨询老师全程把关助力申请:'中美两国咨询老师全程把关助力申请',
            已完成标准化考试 :"已完成标准化考试，时间和精力充足",
            初步确定目标学校:'初步确定目标学校，了解其申请要求',
            无法独立完成文书:'无法独立完成文书，需要指导与修改',
            希望获得专业指导:'希望获得专业指导，提高录取成功率',
            专业咨询老师评价学生背景:'专业咨询老师评价学生背景，提供选校建议',
            专业文书老师指导创作思路:'专业文书老师指导创作思路，修改申请文书',
            可灵活选择签注指导:'可灵活选择签注指导、行前辅导等多项服务',
            详细的留学规划:'详细的留学规划',
            基于企业内部庞大的数据库资料:"基于企业内部庞大的数据库资料，同时结合学生的学术背景、兴趣爱好和未来的发展规划，目标院校及专业，专业的课程设置等进行最合适的选校定位规划。",
            全方位的背景提升:'全方位的背景提升',
            根据选校定位为学生匹配相关的背景提升项目和机会:'根据选校定位为学生匹配相关的背景提升项目和机会，包括大厂实习、科研项目、游学项目等，全方位提升学生的整体竞争力。',
            优质的文书材料:'优质的文书材料',
            文书顾问与学生进行头脑风暴:"文书顾问与学生进行头脑风暴，一对一沟通，针对不同专业定制化挖掘学生亮点，从招生官角度撰写个性化高定版的文书材料，拒绝模板化及千篇一律。",
            高效的笔试面试特训:'高效的笔试面试特训',
            HK汇生会拥有丰富的本科:'HK汇生会拥有丰富的本科、硕士、博士笔试面试 辅导经验，由港澳专业名师指导，提供全真模拟面试及专业详细的点评。',
            升学后的留学援助:'升学后的留学援助',
            HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题，提供的学术辅导服务让学生能够顺 利完成留学阶段的学习任务。",
            内地高考成绩:'内地高考成绩',
            面试成绩:'面试成绩',
            达二本线或以上:'达二本线或以上',
            国际生成绩:'国际生成绩',
            HKDSE:"HKDSE",
            SAT:'SAT',
            LEVEL:'A-LEVEL',
            IB:'IB',
            考试成绩:'考试成绩',
            BETC:'BETC-HND等级',
            正规国际高中成绩单:'正规国际高中成绩单',
            高考英语单科成绩达90分:'高考英语单科成绩达90分',
            满分150分制:'（满分150分制）或雅思5.5',
            部分专业需要:'部分专业需要',
            具体分数要求:'具体分数要求',
            点击咨询:'点击咨询',
            学生姓名:'学生姓名',
            申请类型:'申请类型',
            学术成绩:'学术成绩',
            英语成绩:'英语成绩',
            录取大学:'录取大学',
            查看更多:'查看更多',
            Advantages:'ADVANTAGES',
            留学优势:'留学优势',
            Study:'PROGRAMS',
            选择适合你的留学计划:'选择适合你的留学计划',
            SERVICESYSTEM:'SERVICE SYSTEM',
            科学智能的服务体系:'科学智能的服务体系，全面提升你的留学战斗力',
            准备信息:'准备信息',
            Prepareinformation:'Prepare information',
            Introduction:'INTRODUCTION',
            副学位课程介绍:'副学士课程介绍',
            Application:"ENTRANCE REQUIREMENTS",
            Applications:"APPLICATION PERIOD",
            副学士申请条件:'副学士申请条件',
            副学士申请时间流程:'副学士申请时间流程',
            Associate:'OFFERS',
            副学士录取案例:'副学士录取案例',
            副学士升学途径:"副学士升学途径",//新增
            AssociateDegreeProgressionPathway:"ASSOCIATE DEGREE",//新增
        },
        undergraduate:{
            联系我们:'联系我们',
            全套申请计划:'全套申请计划',
            直通车申请计划:'直通车申请计划',
            半DIY申请计划:'半DIY申请计划',
            适合人群:'适合人群',
            项目特色:'项目特色',
            留学咨询:"留学咨询",
            没有留学申请经验:'没有留学申请经验，需要时间规划和申请辅助',
            没有确定申请学校:'没有确定申请学校，需要学校信息和选校分析',
            英语语言能力不足:'英语语言能力不足，不能与招生官无障碍沟通',
            想要提升背景:"想要提升背景，冲刺申请顶级名校",
            想要有专业老师把控整个申请流程:'想要有专业老师把控整个申请流程',
            想有导师全程规划考试及选课流程:'想有导师全程规划考试及选课流程',
            除求学外:'除求学外，还有留美实习工作需求',
            留学工作全指导:'留学工作全指导，根据需求保证就业',
            没量身打造独特背景提升增加竞争优势:'没量身打造独特背景提升增加竞争优势',
            中美两国咨询老师全程把关助力申请:'中美两国咨询老师全程把关助力申请',
            已完成标准化考试 :"已完成标准化考试，时间和精力充足",
            初步确定目标学校:'初步确定目标学校，了解其申请要求',
            无法独立完成文书:'无法独立完成文书，需要指导与修改',
            希望获得专业指导:'希望获得专业指导，提高录取成功率',
            专业咨询老师评价学生背景:'专业咨询老师评价学生背景，提供选校建议',
            专业文书老师指导创作思路:'专业文书老师指导创作思路，修改申请文书',
            可灵活选择签注指导:'可灵活选择签注指导、行前辅导等多项服务',详细的留学规划:'详细的留学规划',
            基于企业内部庞大的数据库资料:"基于企业内部庞大的数据库资料，同时结合学生的学术背景、兴趣爱好和未来的发展规划，目标院校及专业，专业的课程设置等进行最合适的选校定位规划。",
            全方位的背景提升:'全方位的背景提升',
            根据选校定位为学生匹配相关的背景提升项目和机会:'根据选校定位为学生匹配相关的背景提升项目和机会，包括大厂实习、科研项目、游学项目等，全方位提升学生的整体竞争力。',
            优质的文书材料:'优质的文书材料',
            文书顾问与学生进行头脑风暴:"文书顾问与学生进行头脑风暴，一对一沟通，针对不同专业定制化挖掘学生亮点，从招生官角度撰写个性化高定版的文书材料，拒绝模板化及千篇一律。",
            高效的笔试面试特训:'高效笔试面试特训',
            HK汇生会拥有丰富的本科:'HK汇生会拥有丰富的本科、硕士、博士笔试面试 辅导经验，由港澳专业名师指导，提供全真模拟面试及专业详细的点评。',
            升学后的留学援助:'升学后的留学援助',
            HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题，提供的学术辅导服务让学生能够顺 利完成留学阶段的学习任务。",
            学生姓名:'学生姓名',
            申请类型:'申请类型',
            学术成绩:'学术成绩',
            英语成绩:'英语成绩',
            录取大学:'录取大学',
            查看更多:'查看更多',
            Advantages:'ADVANTAGES',
            留学优势:'留学优势',
            Study:'PROGRAMS',
            选择适合你的留学计划:"选择适合你的留学计划",
            SERVICESYSTEM:'SERVICE SYSTEM',
            科学智能的服务体系:'科学智能的服务体系，全面提升你的留学战斗力',
            准备信息:'准备信息',
            Prepareinformation:'Prepare information',
            香港本科申请流程:'香港本科申请流程',
            undergraduate:'APPLICATION PROCESS',
            Undergraduateadmissioncases:"OFFERS",
            本科录取案例:'本科录取案例',

        },
        graduateStudent:{//研究生
            联系我们:'联系我们',
            全套申请计划:'全套申请计划',
            直通车申请计划:'直通车申请计划',
            半DIY申请计划:'半DIY申请计划',
            适合人群:'适合人群',
            项目特色:'项目特色',
            留学咨询:"留学咨询",
            没有留学申请经验:'没有留学申请经验，需要时间规划和申请辅助',
            没有确定申请学校:'没有确定申请学校，需要学校信息和选校分析',
            英语语言能力不足:'英语语言能力不足，不能与招生官无障碍沟通',
            想要提升背景:"想要提升背景，冲刺申请顶级名校",
            想要有专业老师把控整个申请流程:'想要有专业老师把控整个申请流程',
            想有导师全程规划考试及选课流程:'想有导师全程规划考试及选课流程',
            除求学外:'除求学外，还有留美实习工作需求',
            留学工作全指导:'留学工作全指导，根据需求保证就业',
            没量身打造独特背景提升增加竞争优势:'没量身打造独特背景提升增加竞争优势',
            中美两国咨询老师全程把关助力申请:'中美两国咨询老师全程把关助力申请',
            已完成标准化考试 :"已完成标准化考试，时间和精力充足",
            初步确定目标学校:'初步确定目标学校，了解其申请要求',
            无法独立完成文书:'无法独立完成文书，需要指导与修改',
            希望获得专业指导:'希望获得专业指导，提高录取成功率',
            专业咨询老师评价学生背景:'专业咨询老师评价学生背景，提供选校建议',
            专业文书老师指导创作思路:'专业文书老师指导创作思路，修改申请文书',
            可灵活选择签注指导:'可灵活选择签注指导、行前辅导等多项服务',详细的留学规划:'详细的留学规划',
            基于企业内部庞大的数据库资料:"基于企业内部庞大的数据库资料，同时结合学生的学术背景、兴趣爱好和未来的发展规划，目标院校及专业，专业的课程设置等进行最合适的选校定位规划。",
            全方位的背景提升:'全方位的背景提升',
            根据选校定位为学生匹配相关的背景提升项目和机会:'根据选校定位为学生匹配相关的背景提升项目和机会，包括大厂实习、科研项目、游学项目等，全方位提升学生的整体竞争力。',
            优质的文书材料:'优质的文书材料',
            文书顾问与学生进行头脑风暴:"文书顾问与学生进行头脑风暴，一对一沟通，针对不同专业定制化挖掘学生亮点，从招生官角度撰写个性化高定版的文书材料，拒绝模板化及千篇一律。",
            高效的笔试面试特训:'高效笔试面试特训',
            HK汇生会拥有丰富的本科:'HK汇生会拥有丰富的本科、硕士、博士笔试面试 辅导经验，由港澳专业名师指导，提供全真模拟面试及专业详细的点评。',
            升学后的留学援助:'升学后的留学援助',
            HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题，提供的学术辅导服务让学生能够顺 利完成留学阶段的学习任务。",
            学生姓名:'学生姓名',
            申请类型:'申请类型',
            学术成绩:'学术成绩',
            英语成绩:'英语成绩',
            录取大学:'录取大学',
            查看更多:'查看更多',
            研究生申请材料:'研究生申请材料',
            个人简历:'1、个人基本材料（身份证等）',
            推荐信:'2、毕业证和学位证/在读证明',
            学术及实习科研类:'3、成绩单',
            个人陈述:'4、雅思/托福/六级成绩单',
            本科官方成绩单:'5、工作证明或实习证明',
            语言成绩单:'6、获奖证书（若有）',
            其他奖项:'7、文书材料（CV/RL/PS）',
            科研:'8、其他',
            托福:'托福',
            有海外英文授课本科背景可免:'（有海外英文授课本科背景可免）',
             一百加:'100+',
            港中科:'港中科',
            九十加:'90+',
            城理浸:'城理浸',
            八十五加:'85+',
            岭南教达:'岭南教大/其他',
            雅思:'雅思',
            六点五到七:'6.5-7',
            六到六点五:'6-6.5',
            六加:"6+",
            部分专业需要:'部分专业需要',
            具体分数要求:'具体分数要求',
            点击咨询:'点击咨询',
            GRE:'GRE/GMAT',
            Advantages:'ADVANTAGES',
            留学优势:'留学优势',
            Study:'PROGRAMS',
            选择适合你的留学计划:"选择适合你的留学计划",
            SERVICESYSTEM:'SERVICE SYSTEM',
            科学智能的服务体系:'科学智能的服务体系，全面提升你的留学战斗力',
            准备信息:'准备信息',
            Prepareinformation:'Prepare information',
            Postgraduate:'ENTRANCE REQUIREMENTS',
            研究生申请条件:'研究生申请条件',
            PROCESSKEYPOINTS:'APPLICATION PERIOD',
            研究生申请流程:'研究生申请流程',
            Graduateadmissioncases:"OFFERS",
            研究生录取案例:'研究生录取案例',

        },
        overseasstudyDetail:{//香港留学详情
            发布时间:'发布时间：',
            热度:"热度：",
            在线咨询:'在线咨询',
            首页:'首页',
            港漂干货:'港漂干货',
            香港留学:'香港留学',
            PortromptType:"Study in Hong Kong"
        },
        studentapartment:{//学生公寓
            请输入要搜索的房源:'请输入要搜索的房源',
            搜索:'搜索',
            区域:'区域',
            学校:'学校',
            租金:'租金',
            租期类型:'租期类型',
            房间类型:'房间类型',
            性别要求:'性别要求',
            公寓列表:'公寓列表',
            月起:'月起',
            不限:'不限',
            以上:'9000以上',
            三千:'0-3000',
            五千:'3000-5000',
            七千:'5000-7000',
            九千:'7000-9000',
        },
        studentapartmentDetail:{//学生公寓详情
            简介:'简介',
            周边:'周边',
            设施:'设施/服务',
            公寓信息:'公寓信息',
            公寓名称:'公寓名称：',
            附近地铁:'附近地铁：',
            附近大学:'附近大学：',
            详细地址:'详细地址：',
            租期类型:'租期类型：',
            房型:'房型：',
            公寓设施:'公寓设施',
            房间设施:'房间设施',
            公寓服务:'公寓服务',
            租房须知:'租房须知',
            预定流程:'预定流程',
            管理服务费:'管理服务费',
            取消预定:'取消预定',
            月:'月'
        },
        aboutUs:{//关于我们
            致力于提供香港:' 致力于提供香港、澳门、英国、加拿大及外国等国家或地区最专业的留学一站式服务﹕包括副学士、本科、研究生、博士等专业申请、名校直通车计划、转学申请、背景提升、学术辅导、科研项目等等。我们拥有第一手香港院校数据，香港学校排名信息，课程信息，以及留学生活指南。旨在成为专业、优质、诚信的香港本土服务品牌，助学生考进心仪院校。',
            留学生温暖生活社区:'留学生温暖生活社区',
            ivingcommunity:'Living community',
            汇生会成立于xxxx年:'汇生活成立于xxxx年， 是香港领先的xx平台。',
            同时我们致力于提供周全的学生生活服务:'同时我们致力于提供周全的学生生活服务﹕包括学生公寓、接送服务、看房验房、家居保洁、快递代收等，在香港各大院校周边提供学生公寓的选择，为在港留学生提供安全、优质、舒适的生活环境。我们目前已在各大院校周边运营超过150间学生公寓，其中包括全幢式的物业，累积服务人数过千位学生。',
            是香港领先拥有留学:'是香港领先拥有留学+学生公寓服务的一站式平台',
            录取成功率:'录取成功率为90%',
            公寓累积服务人数过千位学生:'公寓累积服务人数过千位学生',
            超过5年高端留学申请经验:'超过5年高端留学申请经验',
            专业顾问团队:'专业顾问团队',
            括名校导师:'(包括名校导师/前招生官担任顾问)',
            为何需要升学顾问:'为何需要升学顾问',
            Whydo:'Why do I need a counselor',
            省时:'省时、专业',
            到外地留学并不是填好表格送出就完事了:' 到外地留学并不是填好表格送出就完事了，如果没有考虑自己未来发展，只看到一间学校评价不错就递交申请入学，很有可能会浪费你的时间和金钱。',
            升学顾问能从每个学校的特点:' 升学顾问能从每个学校的特点，专业角度，录取要求都会有详细的了解和成功个案数据参照。',
            在背景提升方面:' 在背景提升方面，无论是专业课或者英语提升，升学顾问能推荐一些往届学生体验效果较好的培训班',
            往往留学文书材料是学生联系我们的主要原因:'往往留学文书材料是学生联系我们的主要原因，写作是一门艺术，为了让学生入读心仪的大学，会帮助学生尽可能发挖自我，让文书内容更贴切招生官想看到的内容。',
            联系我们:'联络我们',//修改
            客服热线:'客服热线',
            微信公众号:'微信公众号',
            联系地址:'联系地址',
            在线留言:'在线留言',
            姓名:'姓名',
            联系方式:'联系方式',
            邮箱:'邮箱',
            内容:'内容',
            提交:'提交',
            请填写联系方式:"请填写联系方式",
            提交成功:'提交成功',
            已提交:'已提交',
            一站式留学:'一站式留学',
            OverseasStudy:'Overseas Study',
            contactus:'Contact Us',//修改
        },
        admission:{//录取捷报
            首页:'首页',
            录取捷报:'录取捷报',
            副学士:'副学士',
            本科:'本科',
            研究生:'研究生',
            已提交:'已提交',
            AdmissionsuccessReport:'Success Offers',
            APPLICATIONPROCESS:"PARTNERS",
            支持:'支持/合作机构',

        },
        admissionDeatil:{//录取捷报详情
            首页:'首页',
            录取捷报:'录取捷报',
            AdmissionsuccessReport:'Admission success Report',
            APPLICATIONPROCESS:"PARTNERS",
            支持:'支持/合作机构',
            发布时间:"发布时间:",
            热度:"热度：",
            在线咨询:'在线咨询',
        },
        hkgoods:{
            首页:'首页',
            港漂干货:'港漂干货',
            APPLICATIONPROCESS:"PARTNERS",
            支持:'支持/合作机构',
            Portdriftdrycargo:'News'
        },
        Famousschool:{//名校直通车
            HK汇生会的名校直通车计划:'HK汇生活的名校直通车计划已帮助众多背景较差的学生顺利进入名校就读。该计划由港澳教育界资深人士协助全程参与，为学生提供专业优质的教育咨询服务和升学方案，帮助学生进行背景提升及准备申请材料，后期提供专业的笔试面试辅导培训及提供相关支援服务，最终使学生顺利取得名校录取结果，同时能实现不成功便全额退费的承诺。',
            计划合适人群本科:'计划合适人群（本科）',
            不想读预科:'1. 不想读预科、国际大一等桥梁课程',
            预科在读难以通过:'2. 预科在读难以通过，想直入大一',
            语言成绩不达标:'3. 语言成绩不达标',
            计划合适人群研究生:'计划合适人群（研究生）',
            本科院校背景较差:'1. 本科院校背景较差',
            GPA偏低:'2. GPA偏低',
            软能力不足:'4. 软能力不足，零实践经验',
            国内考研失利后想入读名校:'5. 国内考研失利后想入读名校',
            详细的留学规划:"详细的留学规划",
            基于企业内部庞大的数据库资料:' 基于企业内部庞大的数据库资料，同时结合学生的学术背景、兴趣爱好和未来的发展规划，目标院校及专业，专业的课程设置等进行最合适的选校定位规划。',
            全方位的背景提升:'全方位的背景提升',
            根据选校定位为学生匹配相关的背景提升项目和机会:'根据选校定位为学生匹配相关的背景提升项目和机会，包括大厂实习、科研项目、游学项目等，全方位提升学生的整体竞争力。',
            优质的文书材料:'优质的文书材料',
            文书顾问与学生进行头脑风暴:'文书顾问与学生进行头脑风暴，一对一沟通，针对不同专业定制化挖掘学生亮点，从招生官角度撰写个性化高定版的文书材料，拒绝模板化及千篇一律。',
            高效的笔试面试特训:'高效笔试面试特训',
            HK汇生会拥有丰富的本科:"HK汇生会拥有丰富的本科、硕士、博士笔试面试辅导经验，由港澳专业名师指导，提供全真模拟面试及专业详细的点评。",
            升学后的留学援助:'升学后的留学援助',
            HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:'HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题，提供的学术辅导服务让学生能够顺利完成留学阶段的学习任务。',
            香港本土高端留学机:'香港本土高端留学机构',//修改
            年高端申请经验:'6年高端申请服务经验',//修改
            海量名校录取案例:'海量名校录取案例',
            服务模式:'5+1服务模式',
            老师服务:'5位老师服务1名学生',
            包括香港本土老师和名校顾问:"包括香港本土老师和名校顾问",
            对申请结果负责:'对申请结果负责',
            录取成功率:'95%录取成功率',//修改
            录取率:'90%目标名校录取率',//修改
            一站式留学服务:'升学后的全方位援助服务',
            提供完善的接机:'提供完善的签证指导、住宿安排、学术辅导、实习求职规划等服务',
            学生姓名:'学生姓名：',
            申请类型:'申请类型：',
            学术成绩:'学术成绩：',
            英语成绩:'英语成绩：',
            录取大学:'录取大学：',
            查看更多:'查看更多',
            Studyabroadprogram:'DIRECT PATH TO DREAM SCHOOL',
            名校直通车计划优势:"名校直通车计划优势",
            直通车计划服务流程:'直通车计划服务流程',
            Study:'Study abroad program',
            ADVANTAGE:'STRENGTHES',
            选择智优:'HK汇生会，助力学生斩获梦校录取',
            录取捷报:"录取捷报",
            SUCCESSOFFER:'OFFER',
            OverseasStudy:'Overseas Study',
            一站式留学:'一站式留学',
            SERVICESYSTEM:'SERVICE SYSTEM',
            科学智能的服务体系:"科学智能的服务体系，全面提升你的留学战斗力",
            选择汇生会:"选择汇生活,让香港丰富导师资源为你所用",
            优质的定制化文书材料:'优质的定制化文书材料',
            一站式服务流程:'资深留学服务团队',//新增
            专业资深导师团队:'专业资深导师团队',//新增
            包括香港本地教授助阵:'包括香港本地教授助阵',//新增
            海量成功案例:'海量成功案例',//新增
            升学后的全方位援助服务:'升学后的全方位援助服务',//新增
            提供专业的住宿服务:'提供专业的住宿服务、学术辅导及就业指导规划',//新增
        },
        poplypop:{
            汇生汇:'汇生活',//修改
            香港:'香港',
            文化科技有限公司:'文化科技有限公司',
            深圳:'深圳',
            姓名:'姓名',
            请输入:'请输入',
            性别:'性别',
            留学申请:'留学申请',
            微信号:'微信号',
            提交:'提交',
            男:'男',
            女:'女',
            副学士:'副学士',
            本科:'本科',
            研究生:'研究生'
        },
        poplypoptwo:{
            汇生汇:'汇生活',//修改
            香港:'香港',
            文化科技有限公司:'文化科技有限公司',
            深圳:'深圳',
            姓名:'姓名',
            准备就读院校:'准备就读院校',
            请输入目前:'请输入目前/准备就读院校',
            请选择:'请选择',
            请输入:'请输入',
            性别:'性别',
            留学申请:'留学申请',
            微信号:'微信号',
            提交:'提交',
            男:'男',
            女:'女',
            其他:'其他'
        },
        dialog:{
            汇生汇:'汇生活',//修改
            香港:'香港',
            文化科技有限公司:'文化科技有限公司',
            深圳:'深圳',
            留言内容:'留言内容',
            姓名:'姓名',
            电话:'电话（必填）',
            地址:'地址',
            微信:'微信',
            请输入:'请输入',
            提交:'提交',
            请填写手机号:'请填写手机号'
        },
        erweimaDialog:{
            汇生汇:'汇生活',//修改
            香港:'香港',
            文化科技有限公司:'文化科技有限公司',
            深圳:'深圳',
            联系我们:'联系我们',
            了解更多:'了解更多'
        },
        ielts:{
            评分标准:'评分标准',
            总分:"总分：99分",
            选择模式:'选择模式',
            考试模式:'考试模式',
            考试内容:'考试内容',
            考试流程:'考试流程',
            报名程序:'报名程序',
            考后服务:'考后服务', 
        },
        toefl:{
            考试形式:'考试形式',
            退考与转考:"退考与转考",
            成绩复议:'成绩复议',
            考试流程:'考试流程',
        },
        scientificResearch:{
            论文修改:'论文修改 . 润色发表',
            Paper:'Paper revision — polishing for publication',
            项目流程:'项目流程',
            导师根据学生原有论文提出修改建议:'导师根据学生原有论文提出修改建议，固定1小时讲解修改建议，学生自主初步修改后，导师再进行润色，定稿。',
            适合学生:'适合学生',
            适合已有论文的学生:'适合已有论文的学生',
            项目导师:'项目导师',
            双一流名校博士:'双一流名校博士、博士后、大学讲师',
            学术成果:'学术成果',
            综述型论文:'综述型论文',
            发表级别:'发表级别',
            国际会议论文:'CPCI/EI国际会议论文',
            项目收获:'项目收获',
            修改完善的论文:'修改完善的论文 论文录用通知',
            综述型论文辅导:'综述型论文辅导',
            Literature:'Literature Review Tutoring',
            项目周期:'项目周期',
            包含12课时授课:'12个月，包含12课时授课（参考）+无限次课后答疑',
            适合全部学科:'适合全部学科，学习意愿较强的学生',
            理工:"理工、社会科学、商科研究型论文，带研究模型（或实验）10版面左右（近万词）论文",
            国际会议录用:'CPCI/EI国际会议录用',
            篇独作的论文:'1篇独作的论文；导师推荐信',
            录用通知:'SCI录用通知；完整的科研经历',
            核心型论文辅导:'核心型论文辅导',
            Core:'Core Thesis Tutoring',
            结果导向:'结果导向，不限次数辅导答疑',
            本科及以上学生:'本科及以上学生、需核心期刊发表经历',
            代表性案例:'代表性案例·学员故事',
            学习方向:'学习方向：心理学',
            高中学校:'高中学校：深圳耀华实验学校',
            学生成绩:'学生成绩：托福101分，SAT 1480分',
            课题名称:' 课题名称：探讨成本和代价对于人们无私助人行为的影响',
            Matt钟情于心理学专业:'Matt钟情于心理学专业，目标学校是Reed College。但是在普遍托福110,SAT1500的竞争者中，依旧倍感压力。Matt在翻阅学校信息的时候也发现，心理学在后续的学习中需要做大量的研究。于是Matt决定提前做一个相关的科研课題，以此来获取梦校的青睐。',
            在确认了心理学的大方向之后:'在确认了心理学的大方向之后，在导师的建议下，选择了心理学与经济学交叉的课题，使得心理学研究有了一个新的落脚点。导师悉心指导，开题，文献阅读，采访、收集数据、整合分析，论文撰写，这一路走来每一天都会面对不同的桃战，感受到科研独特的魅力。终于，论文成功发表到国际CPCI会议期刊，并且受邀参与ICSSH',
            位于京都的会议:'2017 位于京都的会议。科研结果丰硕，Matt更加坚定了要在心理学领域送行深耕的信念。科研论文项目是除成绩以外最能证明学术能力的材料，他把这段经历写到申请文书当中，表明自己对目标专业的钟情以及学术潜力，最终版利收获了Reed',
            College:' College, Macalester College, Lafayette College等13所学校的offer，最终确认入该Reed Collese学院开启新的探索之旅。 可喜的是,2017年11月，Matt先借高中阶段的科研经历，成为全校难一一个以新生身份进入里德学院Leaming&Adaptive Behav/ior Laboratory试验室的学生。在2018年12月，Matt in Chicago,并获得了里德学院颁发的奖学金，用于出席行为分析协会国际会设(ABAI)。',
            EI源刊:'SCI JCR3区/EI源刊',
            导师推荐信:'1篇独作的论文；导师推荐信',
            完整的科研经历:'SCI录用通知；完整的科研经历',
            Projectoverview:'OVERVIEW',
            项目概述:"项目概述",
            Productintroduction:'PRODUCTS',
            产品简介:'产品简介',
            Studentstory:'Student story',
            MattWan:'Matt Wan',

        },
        interrnshipprogram:{
            strength:'STRENGTHES',
            实力覆盖行业:'实力覆盖行业',
            Suitableforpeople:'CUSTOMERS',
            适合人群:'适合人群',
            internship:'INTERNSHIP',
            热门实习介绍:'热门实习介绍',
            合作企业:"合作企业",
            Suitableforpeoples:'PARTNERS',
        },
        coach:{
            Entered:"OFFER LIST",
            已经进入的名企列表:"已经进入的名企列表",
            服务流程:'服务流程',
            Serviceprocess:'Service process',
            Successstories:'SUCCESSFUL CASES',
            成功案例:'成功案例',
        },
        paper:{
            Projectprocess:'SERVICES',
            项目流程:'项目流程',
            Thesiscounselingprocess:'GUIDANCE PROCESS',
            论文辅导流程:'论文辅导流程',
            Coachingprocess:'GUIDANCE PROCESS',
            Essay辅导流程:"Essay 辅导流程",
            Servicehighlights:"STRENGTHES",
            服务亮点:'服务亮点',
            服务保障:'服务保障',
            Serviceguarantee:'SERVICE GUARANTEE',
        },
        tutor:{
            Famousteacherguidance:'PROFESSIONAL GUIDANCE',
            名师辅导:'名师辅导',
            Coachingprocess:'PROCESS',
            辅导流程:'辅导流程',
            Serviceadvantage:'STRENGTHES',
            服务优势:'服务优势'
        },
        studyabroad:{
            Servicecontent:'Service content',
            笔试面试服务内容:'笔试面试服务内容',
            Serviceadvantage:'Service advantage',
            服务优势:'服务优势',
            Serviceprocess:'Service process',
            服务流程:'服务流程',
        },
        entrance:{
            首页:'首页 > 香港高校',
            PortromptType:"UNIVERSITIES",
            APPLICATIONPROCESS:'PARTNERS',
            支持:'支持/合作机构',
            
        },
        join:{
            实习岗位:'实习岗位',
            全职岗位:'全职岗位'
        }
        

  };
  