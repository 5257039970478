import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    languageType:1,//1:中文简体2:英文3:中文繁体
    // baseUrl:'http://192.168.2.113:5100/life-app',
    baseUrl:"https://www.sdlivingculture.com/life-app",
    info:{},//网站信息
  },
  mutations: {
    setinfo(state,obj){
        state.info=obj
    },
    
  },
  actions: {
  },
  modules: {
  },
  getters: {
  },
})
 