import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import ElementUI from 'element-ui';
import i18n from "@/i18n";
import 'element-ui/lib/theme-chalk/index.css';
import './util/rem'
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);//图片懒加载

// Vue.mixin({
//     methods: {
//       translatetext(content) {
//         return this.$t(`i18n.${content}`);
//       },
//     },
//   });
Vue.config.productionTip = false
Vue.use(ElementUI);
//或者自定义配置插件
Vue.use(VueLazyload)
new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
