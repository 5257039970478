<template>
<!--  头部导航栏-->
  <div class="pagecon">
    <div>
      <div class="top-yellow">
        <!-- sm以上≥768px -->
        <div class="top-yellowmian hidden-sm-and-down">
          <el-row :gutter="12">
            <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4">
              <div class="grid-content-left">
                <div class="phone_image">
                  <img src="@/assets/image/phone.png" alt=""/>
                </div>
                <div class="phone-con">
                  <div v-if="customer.hotline"> {{customer.hotline||''}}</div>
                  <div v-if="customer.phone"> {{customer.phone||''}}</div>
                </div>
                <div class="weixinimg">
                  <div class="weixinimgone">
                    <img src="@/assets/image/weix.png" alt=""/>
                    <MyEwm class="showewm"
                           :url='baseUrl+customer.wxPublicUrl'></MyEwm>
                  </div>
                  <div class="weixinimgtwo">
                    <img src="@/assets/image/xhs.png" alt=""/>
                    <MyEwm class="showewm"
                           :url='baseUrl+customer.xiaohongshuUrl'></MyEwm>
                  </div>
                </div>

              </div>
            </el-col>
            <el-col :xs="2" :sm="5" :md="5" :lg="5" :xl="5">
              <div class="grid-content-right">
                <div v-for="(item,index) in topList" :key='index' @click="totopindex(index)">{{ item }}</div>
                <div class="text-change">
                  <div @click="toclick(0)" :class="index==0?'fuzacon':''">简</div>
                  <div :class="index==1?'fuzacon':''" @click="toclick(1)">繁</div>
                  <div :class="index==2?'fuzacon':''" @click="toclick(2)">EN</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- sm显示=768px -->
        <div class="top-yellowmian-sm hidden-xs-only hidden-md-and-up">
          <el-row :gutter="10">
            <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4">
              <div class="grid-content-left">
                <div class="phone_image">
                  <img src="@/assets/image/phone.png" alt=""/>
                </div>
                <div class="phone-con">
                 <div v-if="customer.hotline"> {{customer.hotline||''}}</div>
                 <div v-if="customer.phone"> {{customer.phone||''}}</div>
                </div>
                <div class="weixinimg">
                  <div class="weixinimgone">
                    <img src="@/assets/image/weix.png" alt=""/>
                    <MyEwm class="showewm"
                           :url='baseUrl+customer.wxPublicUrl'></MyEwm>
                  </div>
                  <div class="weixinimgtwo">
                    <img src="@/assets/image/xhs.png" alt=""/>
                    <MyEwm class="showewm"
                           :url='baseUrl+customer.xiaohongshuUrl'></MyEwm>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="2" :sm="5" :md="5" :lg="5" :xl="5">
              <div class="grid-content-sm-right">
                <div v-for="(item,index) in topList" :key='index' @click="totopindex(index)">{{ item }}</div>
                <div class="text--sm-change">
                  <div @click="toclick(0)" :class="index==1?'fuzacon':''">简</div>
                  <div :class="index==2?'fuzacon':''" @click="toclick(1)">繁</div>
                  <div :class="index==3?'fuzacon':''" @click="toclick(2)">EN</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- xs显示 <768px-->
        <div class="top-yellowmian-xs hidden-sm-and-up"  v-if="!drawer">
              <div class="grid-content-left-sm">
                <div class="phone_imageone" style="margin-right:43PX" @click="showmenus">
                  <img src="@/assets/image/Combined.png" alt=""/>
                </div>
                 <div class="phone_image"  style="margin-right:8PX" >
                  <img src="@/assets/image/logo.png" alt=""/>
                </div>
                 <div class="phone-con-sm">
                 | ({{$t('tophead.香港')}})({{$t('tophead.深圳')}}){{$t('tophead.文化科技有限公司')}}
                </div>
              </div>
        </div>
        <div v-if="drawer" class="top-yellowmian-xs hidden-sm-and-up">
          <el-drawer
            :title="$t('tophead.我是标题')"
            :with-header="false"
            :visible.sync="drawer"
            :direction="direction"
            :modal-append-to-body='false'
            :show-close='false'
            size='300PX'
            style="height:560PX"
           >
            <div>
              <div class="grid-content-left-sm " style="border-bottom:1PX solid #999;background:#F08A1A">
                <div class="phone_imageone" style="margin:0 20PX" @click="hidemenus">
                  <img src="@/assets/image/Combined.png" alt=""/>
                </div>
                 <div style="font-size:16PX;line-height:60PX">{{$t('tophead.汇生会')}}</div>
                 <div class="text-change" style="margin-top:10PX;margin-left:10PX;font-size:14PX">
                  <div @click="toclick(0)" :class="index==0?'fuzacon':''">简</div>
                  <div :class="index==1?'fuzacon':''" @click="toclick(1)">繁</div>
                  <div :class="index==2?'fuzacon':''" @click="toclick(2)">EN</div>
                </div>
              </div>
              <div class="sm-tk">
                <div :class="(tkList.length-1)==index?'':'borderbottom'" v-for="(item,index) in tkList" :key='index' @click="toPCDetail(index)">{{item}}</div>
              </div>
            </div>
          </el-drawer>
        </div>
      </div>
      <!-- 第二层 -->
      <div class=" hidden-xs-only bot-yellow ">
        <!-- sm以上≥768px -->
        <div class="bot-yellowmian hidden-sm-and-down">
          <el-row :gutter="10">
            <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4">
              <div class="grid-content-bot-left smup">
                <div class="logo_image">
                  <img src="@/assets/image/logo.png" alt=""/>
                </div>
                <div class="company-con">
                  <p style="width: 70PX; font-size:18px">{{$t('tophead.汇生会')}}</p>
                  <div>
                  <div > {{$t('tophead.香港文化科技有限公司')}}</div>
                  <div > {{$t('tophead.深圳文化科技有限公司')}}</div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="2" :sm="7" :md="7" :lg="10" :xl="10">
              <div class="grid-content-bot-right smup ">
                <div v-for="(item,index) in list" :key='index'
                     :class="homeindex==index?'homebottom-border conhover2':'conhover2'">
                  <span class="" @click="tohomeclick(index)" style="margin-right: 10px">{{ item.title||'' }}</span>
                  <div class="conhover"  >
                    <template>
                      <div class="headhover" v-for="(item2,index2) in item.sublist" :key='index2'
                           @click="tosubClick(index,index2)" >{{ item2.subtitle||'' }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- sm显示=768px -->
        <div class="top-yellowmian-sm hidden-xs-only hidden-md-and-up">
          <el-row :gutter="10">
            <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4">
              <div class="grid-content-bot-left  small">
                <div class="logo_image">
                  <img src="@/assets/image/logo.png" alt=""/>
                </div>
                  <div class="company-con">
                  <p style="width: 100PX; font-size:18px">{{$t('tophead.汇生会')}}</p>
                  <div>
                  <div class="word"> {{$t('tophead.香港文化科技有限公司')}}</div>
                  <div class="word"> {{$t('tophead.深圳文化科技有限公司')}}</div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="2" :sm="7" :md="7" :lg="7" :xl="7">
              <div class="grid-content-bot-right smcon">
                <div v-for="(item,index) in list" :key='index' @click="tohomeclick(index)"
                     :class="homeindex==index?'homebottom-border':''">
                  <span class="" @click="tohomeclick(index)">{{ item.title||'' }}</span>
                  <div class="conhover" >
                    <!-- <template>
                      <div class="headhover" v-for="(item2,index2) in item.sublist" :key='index2' >
                           @click="tosubClick(index,index2)">{{ item2.subtitle||'' }}
                      </div>
                    </template> -->
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
import MyEwm from '@/components/ewm'
import {getAddress,joinUs} from '@/api/index.js'

export default {
  props:{
    customer:{
      type:Object,
      default () {
					return {};
				},
    }
  },
  components: {
    MyEwm
  },
  data() {
    return {
      drawer: false,
      direction: 'ltr',
      activeIndex: "1",
      index: 0,
      homeindex: 0,
      hoverhomeindex: 0,
      baseUrl:'',
      content:'',//商城跳转链接
    };
  },
  computed:{
     topList(){
      return [
        this.$t('tophead.商城'),
        this.$t('tophead.名校直通车'),
        this.$t('tophead.学生公寓'),
        this.$t('tophead.港漂干货'),
        this.$t('tophead.成功案例'),
      ]
     } ,
     tkList(){
      return [
         this.$t('tophead.首页'),
         this.$t('tophead.背景提升'),
         this.$t('tophead.香港留学'),
         this.$t('tophead.院校入口'),
         this.$t('tophead.学生公寓'),
         this.$t('tophead.关于我们'),
         this.$t('tophead.商城'),
         this.$t('tophead.名校直通车'),
         this.$t('tophead.港漂干货'),
         this.$t('tophead.成功案例'),
      ]
     },
     list(){
      return [
        {title:this.$t('tophead.首页'), hoveractive: 'false'},
        {
          title:this.$t('tophead.背景提升'), hoveractive: 'false', sublist: [
            {subtitle: this.$t('tophead.语言提升')},
            {subtitle: this.$t('tophead.背景提升')},
            {subtitle: this.$t('tophead.学术')}
          ]
        },
        {
          title:this.$t('tophead.香港留学'), hoveractive: 'false', sublist: [
            {subtitle: this.$t('tophead.副学士')},
            {subtitle: this.$t('tophead.本科')},
            {subtitle: this.$t('tophead.研究生')}
          ]
        },
        {
          title: this.$t('tophead.院校入口'), hoveractive: 'false', sublist: []
        },
        {title:this.$t('tophead.学生公寓'), hoveractive: 'false'},
        {title:this.$t('tophead.关于我们'), hoveractive: 'false'},
      ]
     },
  },
  mounted(){
    this.baseUrl=this.$store.state.baseUrl
    this._getAddress()
    this._joinUs()
  },
  created(){
   // languageType 1:中文简体2:英文3:中文繁体
         if(localStorage.getItem('language')=='zh'){
            this.index=0
         } 
         if(localStorage.getItem('language')=='TW'){
            this.index=1
         }
         if(localStorage.getItem('language')=='En'){
            this.index=2
         }   
  },
  methods: {
    //加入我们
        _joinUs(){
            joinUs({
                category:12
            }).then(res=>{
                console.log(res)
                this.content=res.data.content
            })
        },
    _getAddress(){
      getAddress().then(res=>{
        res.data.forEach(item=>{
          item.subtitle=item.name
        })
        this.list[3].sublist=res.data
      })
    },
    //跳转页面
    toPCDetail(e){

      console.log(e)
      if(e==0){//首页
      this.$router.push({
          path: '/homepage',
        });
      }
      if(e==1){//背景提升
        this.$router.push({
          path: '/background',
        });
      }
      if(e==2){//香港留学
        this.$router.push({
          name: "OverseasStudy",
        });
      }
      if(e==3){//院校入口
        this.$router.push({
          name: "entrance",
          query:{
            addressId:this.list[3].sublist[0].addressId
          }
        });
      }
      if(e==4){//学生公寓
        this.$router.push({
          path: 'studentApartment',
        });
      }
      if(e==5){//关于我们
       this.$router.push({
          name: "AboutUs",
        });
      }
      if(e==6){//商城
        // this.$router.push({
        //   path: '/shoppingMall'
        // });
         window.location.href=this.content
      }
      if(e==7){//名校直通车
      this.$router.push({
          path: '/Famous-school'
        });
      }
      if(e==8){//港漂干货
      this.$router.push({
          path: '/HKgoods'
        });
      }
      if(e==9){//成功案例
      this.$router.push({
          path: '/Admission'
        });
      }
      
        this.drawer=false
    },
    //关闭菜单弹窗
    hidemenus(){
      this.drawer=false
    },
    //展示菜单弹窗
    showmenus(){
      this.drawer=true
    },
    //hover移除事件
    toouthover() {
      this.list.forEach(item => {
        item.hoveractive = false
      })
    },
    //展示下拉框
    tohover(e) {
      this.hoverhomeindex = e
      this.list.forEach(item => {
        item.hoveractive = false
      })
      this.list[e].hoveractive = true
    },
    //公司home切换
    tohomeclick(e) {
      this.homeindex = e
      if (e == 0) {//跳首页
        this.$router.push({
          path: '/homepage',
        });
      }
      if (e == 1) {//背景提升
        this.$router.push({
          path: '/background',
        });
      }
      if (e == 2) {//跳香港留学
        this.$router.push({
          name: "OverseasStudy",
        });
      }
      if (e == 3) {//跳院校入口
        this.$router.push({
          name: "entrance",
          query:{
            addressId:this.list[3].sublist[0].addressId
          }
        });
      }
      if (e == 4) {//跳学生公寓
        this.$router.push({
          path: '/studentApartment',
        });
      }
      if (e == 5) {//跳关于我们
        this.$router.push({
          name: "AboutUs",
        });
      }
    },

    //点击下拉框下方内容
    tosubClick(e1, e) {
      this.homeindex = e1
      if (e1 == 1) {//背景提升
        this.$router.push({
          path: '/background',
        });
      }
      if (e1 == 2) {
        if (e == 0) {//副学士
          this.$router.push({
            path: '/Associate-degree',
          });
        }
        if (e == 1) {//本科
          this.$router.push({
            path: '/undergraduate',
          });
        }
        if (e == 2) {//研究生
          this.$router.push({
            name: "GraduateStudent",
          });
        }

      }
      if (e1 == 3) {//院校入口
      console.log(this.list[3].sublist[e].addressId)
          this.$router.push({
            name: "entrance",
            query:{
              addressId:this.list[3].sublist[e].addressId
            }
          });
      }
    },
    //中英文切换
    toclick(e) {
      this.index = e
      this._getAddress()
      this._joinUs()
      if (e === 0) {//简体
        this.$i18n.locale = "zh";
        localStorage.setItem("language", "zh");
      }
      if (e === 1) {//繁体
        this.$i18n.locale = "TW";
        localStorage.setItem("language", "TW");
      }
      if (e === 2) {//英文
        this.$i18n.locale = "En";
        localStorage.setItem("language", "En");
      }
      this.$emit('keychange')
    },
    totopindex(e) {
      if (e == 0) {//跳转商城
        // this.$router.push({
        //   path: '/shoppingMall'
        // });
        window.location.href=this.content
      }
      if (e == 1) {//跳转名校直通车
        this.$router.push({
          path: '/Famous-school'
        });
      }
      if (e == 3) {//跳转港漂干货
        this.$router.push({
          path: '/HKgoods'
        });
      }
      if (e == 2) {//跳学生公寓
        this.$router.push({
          path: '/studentApartment'
        });
      }
      if (e == 4) {//跳成功案例
        this.$router.push({
          path: '/Admission',
          
        });
      }
    },
  }
};
</script>

<style  scoped>

/deep/ .el-drawer__body {
    background: #FE992B!important;
  }
  .small {
    width: 900px !important;
  }
.pagecon {
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
}

.conhover {
  height: 0px;
  /* position: absolute;
  top: 80PX;
  left: 13vw;
  width:8vw; */
  /* display: none; */
  overflow: hidden;
  transition: all 0.3s;
}

.conhover2:hover .conhover {
  /* display: block; */
  height: 8000px;
}

.headhover {
  width: 100%;
  background: #ffffff;
  text-align: center;
  line-height: 50PX;
  /*height: 50PX;*/
  border-bottom: 2PX solid #f7f7f7;
  position: relative;
  top: -2PX;
  font-size: 12PX;
}

.headhover:hover {
  background: #fe992b;
  color: #fff;
  cursor: pointer;
  border-bottom: 2PX solid #fe992b;
  line-height: 50PX;

}

.top-yellow {
  background: #fe992b;
  color: #fff;
  width: 100vw;
  height: 60PX;
  position: relative;
}

.top-yellowmian {
  width: 1176PX;
  margin: 0 auto;
}

.top-yellowmian-sm {
  width: 1176PX;
  height: 80PX;
  margin: 0 auto;
}

.top-yellowmian-xs {
  width: 90%;
  margin: 0 auto;
}

.grid-content-left {
  width: 250PX;
  height: 60PX;
  /* line-height: 60PX; */
  display: flex;
  flex-direction: row;
  align-content: center;
}
.grid-content-left-sm{
  width: 100%;
  display: flex;
  height: 60PX;
  flex-direction: row;
  align-content: center;
}

.phone_imageone img{
  width: 24PX;
  height: 18PX;
  margin-top: 21PX;
}
.phone_image img {
  width: 28PX;
  height: 28PX;
  margin-top: 16PX;
}

.phone-con {
  width: 170PX;
  height: 60PX;
  color: #fff;
  font-size: 14PX;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.phone-con-sm{
  height: 60PX;
  color: #fff;
  font-size: 14PX;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.weixinimg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.weixinimg img {
  width: 28PX;
  height: 28PX;
  /* margin-top: 16PX; */
  margin-right: 12PX;
}

.weixinimgone, .weixinimgtwo {
  position: relative;
}

.showewm {
  display: none;
}

.weixinimgone:hover .showewm, .weixinimgtwo:hover .showewm {
  display: block;
  cursor: pointer;
}

.text-change {
  background: #ffffff;
  width: 100PX;
  height: 40PX;
  border-radius: 20PX 20PX 20PX 20PX;
  color: #999999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 5PX;
  position: relative;
  right: -15PX;
}

.text--sm-change {
  background: #ffffff;
  width: 100PX;
  height: 40PX;
  border-radius: 20PX 20PX 20PX 20PX;
  color: #999999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 5PX;
}

.text-change div, .text--sm-change div {
  width: 30PX;
  height: 30PX;
  line-height: 30PX;
  text-align: center;
}

.fuzacon {
  color: #fe992b;
}

.grid-content-right {
  position: absolute;
  right: 0;
  text-align: right;
  height: 60PX;
  width: 760PX;
  font-size: 16PX;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.grid-content-right:hover, .grid-content-bot-right:hover, .grid-content-sm-right:hover {
  cursor: pointer;
}

.grid-content-sm-right {
  position: absolute;
  right: 0;
  text-align: right;
  height: 60PX;
  width: 800PX;
  font-size: 16PX;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.grid-content-right img {
  width: 28PX;
  height: 28PX;
  position: absolute;
  right: 0;
}

.bot-yellow {
  background: #fff;
  width: 100vw;
}
.bot-yellowmian {
  width: 1176PX;
  margin: 0 auto;
  height: 80PX;
}

.grid-content-bot-left {
  width:520PX;
  height: 80PX;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo_image img {
  width: 64PX;
  height: 64PX;
}

.company-con {
  display: flex;
  height: 30PX;
  margin-left: 12PX;
  border-left: 1PX solid #999;
  font-size: 14PX;
  padding-left: 12PX;
  align-items: center;
}

.grid-content-bot-right {
  position: absolute;
  right: 0;
  text-align: right;
  height: 80PX;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.grid-content-bot-right > div {
  height: 79PX;
  line-height: 80PX;
}

.grid-content-bot-right img {
  width: 28PX;
  height: 28PX;
  position: absolute;
  right: 0;
}

.smup {
  /*width:600PX;*/
  font-size: 20PX;
}

.smcon {
  width: 50vw;
  font-size: 16PX;
}

.homebottom-border {
  border-bottom: 4PX solid #fe992b;
}
.sm-tk{
  font-size: 16PX;
  color: #fff;
  text-align: left;

}
.sm-tk>div{
  padding-left: 20PX;
  height: 50PX;
  line-height: 50PX;
}
.borderbottom{
  border-bottom: 1px solid #999;
}
</style>
