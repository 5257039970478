import Vue from "vue";
import VueRouter from "vue-router";
import index from "@/view/main/index.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    redirect: "/homepage",
    children: [
      {
        path: "homepage",
        name: "homepage",
        component: () => import("@/view/main/homepage.vue"),
      },
      {
        path: "HKgoods",
        name: "HKgoods",
        component: () => import("@/view/main/HKgoods.vue"),
      },
      {
        path: "Overseas-Study",
        name: "Overseas-Study",
        component: () => import("@/view/main/Overseas-Study.vue"),
      },
      {
        path: "Admission",
        name: "Admission",
        component: () => import("@/view/main/Admission.vue"),
      },
      {
        path: "studentApartment",
        name: "StudentApartment",
        component: () => import("@/view/main/studentApartment.vue"),
      },
      {
        path: "apartmentDetails",
        name: "ApartmentDetails",
        component: () => import("@/view/main/apartmentDetails.vue"),
      },
      {
        path: "aboutUs",
        name: "AboutUs",
        component: () => import("@/view/main/aboutUs.vue"),
      },
      {
        path: "overseasStudy",
        name: "OverseasStudy",
        component: () => import("@/view/main/overseasStudy.vue"),
      },
      {
        path: "background",
        name: "background",
        component: () => import("@/view/main/background.vue"),
      },
      {
        path: "Famous-school",
        name: "Famous-school",
        component: () => import("@/view/main/Famous-school.vue"),
      },
      {
        path: "graduateStudent",
        name: "GraduateStudent",
        component: () => import("@/view/main/graduateStudent.vue"),
      },
      {
        path: "entrance",
        name: "entrance",
        component: () => import("@/view/main/entrance.vue"),
      },
      {
        path: "undergraduate",
        name: "undergraduate",
        component: () => import("@/view/main/undergraduate.vue"),
      },
      {
        path: "Associate-degree",
        name: "Associate-degree",
        component: () => import("@/view/main/Associate-degree.vue"),
      },
      {
        path: "tutor",
        name: "tutor",
        component: () => import("@/view/main/tutor.vue"),
      },
      {
        path: "paper",
        name: "paper",
        component: () => import("@/view/main/paper.vue"),
      },
      {
        path: "Study-abroad",
        name: "Study-abroad",
        component: () => import("@/view/main/Study-abroad.vue"),
      },
      {
        path: "Internship-program",
        name: "Internship-program",
        component: () => import("@/view/main/Internship-program.vue"),
      },
      {
        path: "scientificResearch",
        name: "ScientificResearch",
        component: () => import("@/view/main/scientificResearch.vue"),
      },
      {
        path: "coach",
        name: "Coach",
        component: () => import("@/view/main/coach.vue"),
      },
      {
        path: "ielts",
        name: "Ielts",
        component: () => import("@/view/main/ielts.vue"),
      },
      {
        path: "toefl",
        name: "toefl",
        component: () => import("@/view/main/toefl.vue"),
      },
      {
        path: "GRE",
        name: "GRE",
        component: () => import("@/view/main/GRE.vue"),
      },
      {
        path: "GMAT",
        name: "GMAT",
        component: () => import("@/view/main/GMAT.vue"),
      },
      {
        path: "AsmissionDetail",
        name: "AsmissionDetail",
        component: () => import("@/view/main/AsmissionDetail.vue"),
      },
      {
        path: "AssoStudy",
        name: "AssoStudy",
        component: () => import("@/view/main/AssoStudy.vue"),
      },
      {
        path: "undergraduateStudy",
        name: "undergraduateStudy",
        component: () => import("@/view/main/undergraduateStudy.vue"),
      },
      {
        path: "gra-stuphone",
        name: "gra-stuphone",
        component: () => import("@/view/main/gra-stuphone.vue"),
      },
      {
        path: "shoppingMall",
        name: "shoppingMall",
        component: () => import("@/view/main/shoppingMall.vue"),
      },
      {
        path: "joinus",
        name: "joinus",
        component: () => import("@/view/main/joinus.vue"),
      }
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
