<template>
  <div>
    <tophead :customer='customer' @keychange='keychange'></tophead>
    <div :class="screenWidth>768?'content hidden-xs-only':'content2 hidden-sm-and-up'">
      <router-view :key='key'></router-view>
    </div>
    <tofooter :customer='customer'> </tofooter>
  </div>
</template>

<script>
import tophead from '@/components/tophead.vue'
import tofooter from '@/components/footer.vue'
import "element-ui/lib/theme-chalk/display.css";
import {customerService} from "@/api/index.js"

export default {
  components: {
    tophead,
    tofooter
  },
  data(){
    return{
      screenWidth: document.body.clientWidth,
      customer:{},
      key:0
    }
  },
  
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        console.log(this.screenWidth);
        that.screenWidth = window.screenWidth;
      })();
    };
    window.addEventListener("resize", () => {
      this.update();
    });
    this._customerService()
  },
  methods: {
    keychange(){
      this.key+=1
      this._customerService()
    },
    _customerService(){
      customerService().then(res=>{
        console.log(res)
        this.customer=res.data
        this.$store.commit('setinfo',this.customer)
      })
    },
    update() {
      this.$nextTick(() => {
        this.$forceUpdate()
      });
    },
  }
}
</script>

<style scoped>

.content {
  margin-top: 140PX;
  width: 100vw;

}
.content2{
  margin-top: 60PX;
  width: 100vw;
}
</style>