import Vue from "vue";
import VueI18n from "vue-i18n";
import elEn from "element-ui/lib/locale/lang/en";//英文
import elZh from "element-ui/lib/locale/lang/zh-CN";//简体中文
import elTW from "element-ui/lib/locale/lang/zh-TW";//繁体中文
import En from "./En";
import zh from "./zh";
import tw from "./tw";

Vue.use(VueI18n);

const messages = {
    En: {
    ...En,
    ...elEn,
  },
  zh: {
    ...zh,
    ...elZh,
  },
  TW: {
    ...tw,
    ...elTW,
  },
};

const i18n = new VueI18n({
  locale: localStorage.getItem("language") || "zh",
  messages,
});

export default i18n;
