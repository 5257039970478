// 英文
export default {
    tophead: {
      商城: "Store",
        名校直通车:" Service",
        学生公寓:'Apartment',
        港漂干货:'News',
        成功案例:'Offer',
        首页:"Home",
        背景提升:"Improvement",//修改
        香港留学:"Services",
        院校入口:"University",
        关于我们:"About us",
        语言提升:'Language improvement',
        学术:'Academic',
        副学士:'Associate Degree',
        本科:'Undergraduate',
        研究生:'Postgraduate',
        香港文化科技有限公司:'(Hong Kong) Culture Technology Co. Ltd',//修改
        深圳文化科技有限公司:'(Shenzhen) Culture Technology Co.Ltd',//修改
        汇生会:'Student Living',
        香港:'Hong Kong',
        深圳:'Shen Zhen',
        文化科技有限公司:'Culture Technology Ltd.',
        我是标题:'This is the title'
    },
    footer:{
        加入我们:"Join us",
        联系电话:'Contact number',
        地址:'Address',
        小红书:'Little red book',
        公众号:'WeChat public account',
        咨询号:'Inquiry number',
        汇生会:'Student Living',//修改
        香港:'Hong Kong',
        深圳:'Shen Zhen',
        文化科技有限公司:'Culture Technology Ltd.',

    },
    homepage:{//首页
        留学申请计划:'Study Abroad Application Program',
        副学士:'（Associate Degree/Undergraduate/Graduate/PhD）',
        留学文书创作:'Study Abroad Document Creation',
        为您提供有效的学习规划:'Provide you with an effective study plan',
        留学面试辅导:'Study Abroad Interview Counseling',
        帮您逐一攻克面试流程:'Help you overcome the interview process one by one',
        背景提升室:'Background improvement room',
        全方位提高竞争力:'Improving competitiveness in all aspects',
        名校直通车:'Famous school through train',
        学校直录:'Direct school recording, dream of a famous school',
        香港学生公寓:'Hong Kong student apartment',
        给你一个不一样的家:'Give you a different home',
        服务精选:"SERVICES",
        HK汇生会:'HK STUDENT LIVING',
        支持:"Support/Cooperative institutions",
        Serviceselection:'SERVICES',
        Strengthes:'STRENGTHES',
        APPLICATIONPROCESS:'PARTNERS',

    },
    background:{//背景提升
        IELTS:'IELTS',
        TOEFL:'TOEFL',
        GRE:'GRE',
        EMET:'GMAT',
        实习项目:'Internship program',
        科研项目:'Scientific research projects',
        求职辅导:'Job search counseling',
        论文辅导:'Thesis tutoring',
        课程辅导:'Course guidance',
        留学面试辅导:'Study abroad interview counseling',
        提示类型:'Prompt type',
        PromptType:"Prompt Type",

    },
    overseasStudy:{//香港留学
        联系我们:'Contact us',
        全套申请计划:'Complete application plan',
        直通车申请计划:'Through Train Application Program',
        半DIY申请计划:'Semi-DIY Application Program',
        适合人群:'Suitable for the crowd',
        项目特色:'Program features',
        留学咨询:"Program features",
        没有留学申请经验:'No experience in studying abroad, need time planning and application assistance',
        没有确定申请学校:'No school to apply for, school information and school selection analysis are required',
        英语语言能力不足:'Insufficient English language skills to communicate with admissions officers',
        想要提升背景:"A desire to enhance your background and apply to top universities",
        想要有专业老师把控整个申请流程:'Want a professional teacher to control the entire application process',
        想有导师全程规划考试及选课流程:'Want a tutor to plan the exam and course selection process',
        除求学外:'Apart from studying, there is also a need to stay in Hong Kong for internship and work',
        留学工作全指导:'Full guidance on studying abroad, ensuring employment according to demand',
        没量身打造独特背景提升增加竞争优势:'Tailor-made unique background to enhance competitive advantage',
        中美两国咨询老师全程把关助力申请:'Consultants in China and Hong Kong will be on hand to help you through the application process',
        已完成标准化考试 :"Completed standardized tests with sufficient time and energy",
        初步确定目标学校:'Preliminarily determine the target school and understand its application requirements',
        无法独立完成文书:'Unable to complete the document independently, need guidance and revision',
        希望获得专业指导:'Hope to get professional guidance and improve the success rate of admission',
        专业咨询老师评价学生背景:"Professional consultants evaluate students backgrounds and provide school selection suggestions",
        专业文书老师指导创作思路:'Professional document teachers guide creative ideas and revise application documents',
        可灵活选择签注指导:'You can flexibly choose multiple services such as endorsement guidance, pre-departure counseling, etc.',
        详细的留学规划:'Detailed study abroad plan',
        基于企业内部庞大的数据库资料:"Based on the huge internal database of the enterprise, it combines the students' academic background, hobbies and future development plans, target colleges, majors, and curriculum settings. Provide the most suitable school selection and positioning plan.",
        全方位的背景提升:'All-round background enhancement',
        根据选校定位为学生匹配相关的背景提升项目和机会:'According to the selected school positioning, students are matched with relevant background improvement projects and opportunities, including internships in large factories, scientific research projects, study tour projects, etc. Improve the overall competitiveness of students in an all-round way.',
        优质的文书材料:'High-quality paperwork',
        文书顾问与学生进行头脑风暴:"The clerical consultant teacher brainstorms with the students and conducts one-to-one communication. Explore student highlights for different majors. Write personalized high-end version of the paper materials from the perspective of admissions officers, reject templated and stereotyped.",
        高效的笔试面试特训:'Efficient written test interview training',
        HK汇生会拥有丰富的本科:"HK Student Living has rich experience in interview counseling for undergraduate, master's and doctoral written examinations. It is guided by famous teachers from Hong Kong and Macao, and provides real mock interviews and professional detailed comments.",
        升学后的留学援助:'Study abroad assistance after entering university',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"The student apartment provided by the HK Student Living perfectly solves the accommodation problem of students studying in Hong Kong, and the academic counseling service provided enables students to successfully complete the study tasks during the study abroad stage.",
        申请前:'Before application',
        Beforeapplication:"Before application",
        选校定位:'School selection',
        选校分析:'School selection analysis',
        方案定位:'Program positioning',
        留学策略定制:'Study abroad strategy customization',
        个月VIP定制私人留学顾问:'12-month VIP customized private study abroad consultant',
        名师实时咨询:'Real-time consultation of famous teachers',
        选校方案定位:'School selection program positioning',
        申请策略定制:'Application strategy customization',
        申请材料指导:'Application material guide',
        网申课程全覆盖:'Full coverage of online application courses',
        申请中:'During application',
        Inapplication:'During application',
        文书材料:'Documentary materials',
        全套文书:'Complete set of documents',
        CV:'CV',
        RS:"RS",
        R2:'R2',
        PCE:'PCE',
        文书翻译:'Document translation',
        高定文书:'high-end documents',
        颜色:'Color',
        网申助手:'Online application assistant',
        网申管家:'Online application manager',
        面试指导:'Interview guidance',
        申请后:'After application',
        application:'After application',
        签证指导:'Visa Guidance',
        落地服务:'landing service',
        海外租房:'Overseas rental',
        留学迎新礼包:'Study abroad welcome package',
        香港本土高端留学机:"Hong Kong's local high-end study abroad institutions",//修改
        年高端申请经验:"6 years of high-end application experience",
        海量名校录取案例:'A large number of famous school admission cases',
        服务模式:"5+1 service mode",
        位老师服务:'5 teachers serving 1 student',
        包括香港本土老师和名校顾问:'Including local teachers in Hong Kong and consultants from prestigious schools',
        对申请结果负责:'Responsible for application results',
        录取成功率:"95% admission success rate",//修改
        录取率:"90% of the target elite school acceptance rate",//修改
        一站式留学服务:"One-stop study abroad service",
        提供完善的接机:'Provide comprehensive visa guidance, accommodation arrangements, academic counseling, internship job planning and other services',
        学生姓名:'Student Name',
        申请类型:'Application Type',
        学术成绩:'Academic Score',
        英语成绩:'English grade',
        录取大学:'Admitted University',
        查看更多:'See more',
        Advantages:'ADVANTAGES',
        留学优势:'Advantages of studying abroad',
        Study:'PROGRAMS',
        选择适合你的留学计划:'Choose the study abroad program that suits you',
        SERVICE:'SERVICE SYSTEM',
        科学智能的服务体系:"Scientific and intelligent service system to comprehensively improve your combat effectiveness in studying abroad",
        Prepare:'Prepare information',
        准备信息:'Prepare information',
        汇生汇留学服务:"HK Student Living's study abroad service will help you overcome the key points of the application process one by one",//修改
        PROCESS:'PROCESS KEY POINTS',
        ADVANTAGE:'ADVANTAGE',
        选择汇生汇:"Choose HK Student Living and let Hong Kong's rich tutor resources work for you",//修改
        录取捷报:'Offer News',
        SUCCESSOFFER:"OFFER",
        APPLICATION:'APPLICATION PROCESS',
        支持:'Support/Cooperative institutions',
        多种留学方案匹配不同人群:'A variety of study abroad programs to match different groups of people',
        对选校定位及留学申请流程不清楚:"Not clear about the location of the school selection and the application process for studying abroad",//新增
        缺少时间精力自行准备申请材料:'Lack of time and energy to prepare application materials by yourself',//新增
        根据学生条件提供定制式留学方案:'Provide customized study abroad programs according to student conditions',//新增
        提供准确的申请时间规划:'Provide accurate application time planning',//新增
        提供详细全面的申请材料指导:'Provide detailed and comprehensive guidance on application materials',//新增
        根据相关专业和面试要求提供面试服务和指引材料:"Provide interview services and guidance materials according to relevant majors and interview requirements",//新增
        本科院校背景较差:'Poor undergraduate background',//新增
        GPA及语言成绩偏低:'Low GPA and language scores',//新增
        软实力不足:'Insufficient soft power and lack of practical experience',//新增
        国内考研失利后想入读名校:'After failing the domestic postgraduate entrance examination and want to enter a famous school',//新增
        从前期咨询到成功入学全程一站式服务收获更高性价比:'One-stop service from early consultation to successful admission to gain more cost-effective',//新增
        资深教育界专家顾问:'Senior education expert consultant, one-on-one interview professional guidance',//新增
        提供独家资源:"Provide exclusive resources and follow-up services throughout the process to ensure the smooth enrollment of final students",//新增
        有明确的选校定位:"Have a clear school selection",//新增
        主动性较强且有清晰的申请规划:"Strong initiative and a clear application plan",//新增
        英语阅读及写作能力较强:'Strong English reading and writing skills',//新增
        针对学生需求提供细分服务:'Provide segmented services for student needs (including paperwork services, online application guidance, etc.)',//新增
        拒绝捆绑式销售:'Refuse bundled sales, cost-effective',//新增
        有效沟通:'Effective communication and professional guidance',//新增
        During:"During application",//新增
        After:'After application',//新增
        个人陈述:"PS",//新增
        简历:'CV',//新增
        推荐信:"RL",//新增
        其他:'Other（eg.Essay）',//新增
        选择汇生会:" HK Student Living, helping students gain admission to their dream school",//新增
        年高端申请服务经验:"6 years of high-end application service experience",//新增
        优质的定制化文书材料:'High-quality customized documents',//新增
        一站式服务流程:'Senior study abroad service team',//新增
        专业资深导师团队:'Professional senior mentor team',//新增
        包括香港本地教授助阵:'Including local Hong Kong professors to help out',//新增
        海量成功案例:'Massive success stories',//新增
        升学后的全方位援助服务:'Comprehensive assistance services after further studies',//新增
        提供专业的住宿服务:'Provide professional accommodation services, academic counseling and career guidance planning',//新增
        副学士:'Associate Degree',
        本科:"Undergraduate",
        研究生:'Postgraduate',
        副学士升学途径:"SERVICES",
    },
    associatedegree:{//副學士
        联系我们:'Contact us',
        全套申请计划:'Complete application plan',
        直通车申请计划:'Through Train Application Program',
        半DIY申请计划:'Semi-DIY Application Program',
        适合人群:'Suitable for the crowd',
        项目特色:'Program features',
        留学咨询:"Program features",
        没有留学申请经验:'No experience in studying abroad, need time planning and application assistance',
        没有确定申请学校:'No school to apply for, school information and school selection analysis are required',
        英语语言能力不足:'Insufficient English language skills to communicate with admissions officers',
        想要提升背景:"A desire to enhance your background and apply to top universities",
        想要有专业老师把控整个申请流程:'Want a professional teacher to control the entire application process',
        想有导师全程规划考试及选课流程:'Want a tutor to plan the exam and course selection process',
        除求学外:'Apart from studying, there is also a need to stay in Hong Kong for internship and work',
        留学工作全指导:'Full guidance on studying abroad, ensuring employment according to demand',
        没量身打造独特背景提升增加竞争优势:'Tailor-made unique background to enhance competitive advantage',
        中美两国咨询老师全程把关助力申请:'Consultants in China and Hong Kong will be on hand to help you through the application process',
        已完成标准化考试 :"Completed standardized tests with sufficient time and energy",
        初步确定目标学校:'Preliminarily determine the target school and understand its application requirements',
        无法独立完成文书:'Unable to complete the document independently, need guidance and revision',
        希望获得专业指导:'Hope to get professional guidance and improve the success rate of admission',
        专业咨询老师评价学生背景:'Professional consultants evaluate students backgrounds and provide school selection suggestions',
        专业文书老师指导创作思路:'Professional document teachers guide creative ideas and revise application documents',
        可灵活选择签注指导:'ou can flexibly choose multiple services such as endorsement guidance, pre-departure counseling, etc.',
        详细的留学规划:'Detailed study abroad plan',
        基于企业内部庞大的数据库资料:"Based on the huge internal database of the enterprise, it combines the students' academic background, hobbies and future development plans, target colleges, majors, and curriculum settings. Provide the most suitable school selection and positioning plan.",
        全方位的背景提升:'All-round background enhancement',
        根据选校定位为学生匹配相关的背景提升项目和机会:'According to the selected school positioning, students are matched with relevant background improvement projects and opportunities, including internships in large factories, scientific research projects, study tour projects, etc. Improve the overall competitiveness of students in an all-round way.',
        优质的文书材料:'High-quality paperwork',
        文书顾问与学生进行头脑风暴:"The clerical consultant teacher brainstorms with the students and conducts one-to-one communication. Explore student highlights for different majors. Write personalized high-end version of the paper materials from the perspective of admissions officers, reject templated and stereotyped.",
        高效的笔试面试特训:'Efficient written test interview training',
        HK汇生会拥有丰富的本科:"HK Student Living has rich experience in interview counseling for undergraduate, master's and doctoral written examinations. It is guided by famous teachers from Hong Kong and Macao, and provides real mock interviews and professional detailed comments.",
        升学后的留学援助:'Study abroad assistance after entering university',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"The student apartment provided by the HK Student Living perfectly solves the accommodation problem of students studying in Hong Kong, and the academic counseling service provided enables students to successfully complete the study tasks during the study abroad stage.",
        内地高考成绩:'Mainland college entrance examination results',
        面试成绩:'Interview results',
        达二本线或以上:'Level 2 or above',
        国际生成绩:'International Student Results',
        HKDSE:"HKDSE",
        SAT:'SAT',
        LEVEL:'A-LEVEL',
        IB:'IB',
        考试成绩:'Test scores',
        BETC:'BETC-HND grade',
        正规国际高中成绩单:'Formal international high school transcript',
        高考英语单科成绩达90分:'College Entrance Exam English single subject score of 90 points',
        满分150分制:'(out of 150 points) or IELTS 5.5',
        部分专业需要:'Some professional needs',
        具体分数要求:'Specific score requirements',
        点击咨询:'Click for inqueries',
        学生姓名:'Student name',
        申请类型:'Application type',
        学术成绩:'Academic record',
        英语成绩:'English results',
        录取大学:'Admission to the University',
        查看更多:'See more',
        Advantages:'ADVANTAGES',
        留学优势:'Advantages of studying abroad',
        Study:'PROGRAMS',
        选择适合你的留学计划:'Choose a study abroad program that suits you',
        SERVICESYSTEM:'SERVICE SYSTEM',
        科学智能的服务体系:'Scientific and intelligent service system, comprehensively enhance your combat effectiveness in studying abroad',
        准备信息:'Prepare information',
        Prepareinformation:'Prepare information',
        Introduction:'INTRODUCTION',
        副学位课程介绍:'Introduction to associate degree courses',
        Application:"ENTRANCE REQUIREMENTS",
        Applications:"APPLICATION PERIOD",
        副学士申请条件:'Application conditions for associate degree',
        副学士申请时间流程:'Associate Degree Application Timeline',
        Associate:'OFFERS',
        副学士录取案例:'Associate degree admission cases',
        副学士升学途径:"Associate Degree Progression Pathway",//新增
        AssociateDegreeProgressionPathway:"ASSOCIATE DEGREE",//新增
    },
    undergraduate:{
        联系我们:'Contact us',
        全套申请计划:'Complete application plan',
        直通车申请计划:'Through Train Application Program',
        半DIY申请计划:'Semi-DIY Application Program',
        适合人群:'Suitable for the crowd',
        项目特色:'Program features',
        留学咨询:"Program features",
        没有留学申请经验:'No experience in studying abroad, need time planning and application assistance',
        没有确定申请学校:'No school to apply for, school information and school selection analysis are required',
        英语语言能力不足:'Insufficient English language skills to communicate with admissions officers',
        想要提升背景:"A desire to enhance your background and apply to top universities",
        想要有专业老师把控整个申请流程:'Want a professional teacher to control the entire application process',
        想有导师全程规划考试及选课流程:'Want a tutor to plan the exam and course selection process',
        除求学外:'Apart from studying, there is also a need to stay in Hong Kong for internship and work',
        留学工作全指导:'Full guidance on studying abroad, ensuring employment according to demand',
        没量身打造独特背景提升增加竞争优势:'Tailor-made unique background to enhance competitive advantage',
        中美两国咨询老师全程把关助力申请:'Consultants in China and Hong Kong will be on hand to help you through the application process',
        已完成标准化考试 :"Completed standardized tests with sufficient time and energy",
        初步确定目标学校:'Preliminarily determine the target school and understand its application requirements',
        无法独立完成文书:'Unable to complete the document independently, need guidance and revision',
        希望获得专业指导:'Hope to get professional guidance and improve the success rate of admission',
        专业咨询老师评价学生背景:"Professional consultants evaluate students backgrounds and provide school selection suggestions",
        专业文书老师指导创作思路:'Professional document teachers guide creative ideas and revise application documents',
        可灵活选择签注指导:'You can flexibly choose multiple services such as endorsement guidance, pre-departure counseling, etc.',详细的留学规划:'Detailed study abroad plan',
        基于企业内部庞大的数据库资料:"Based on the huge internal database of the enterprise, it combines the students' academic background, hobbies and future development plans, target colleges, majors, and curriculum settings. Provide the most suitable school selection and positioning plan.",
        全方位的背景提升:'All-round background enhancement',
        根据选校定位为学生匹配相关的背景提升项目和机会:'According to the selected school positioning, students are matched with relevant background improvement projects and opportunities, including internships in large factories, scientific research projects, study tour projects, etc. Improve the overall competitiveness of students in an all-round way.',
        优质的文书材料:'High-quality paperwork',
        文书顾问与学生进行头脑风暴:"The clerical consultant teacher brainstorms with the students and conducts one-to-one communication. Explore student highlights for different majors. Write personalized high-end version of the paper materials from the perspective of admissions officers, reject templated and stereotyped.",
        高效的笔试面试特训:'Efficient written test interview training',
        HK汇生会拥有丰富的本科:"HK Student Living has rich experience in interview counseling for undergraduate, master's and doctoral written examinations. It is guided by famous teachers from Hong Kong and Macao, and provides real mock interviews and professional detailed comments.",
        升学后的留学援助:'Study abroad assistance after entering university',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"The student apartment provided by the HK Student Living perfectly solves the accommodation problem of students studying in Hong Kong, and the academic counseling service provided enables students to successfully complete the study tasks during the study abroad stage.",
        学生姓名:'Student Name',
        申请类型:'Application Type',
        学术成绩:'Academic Score',
        英语成绩:'English grade',
        录取大学:'Admitted University',
        查看更多:'See more',
        Advantages:'ADVANTAGES',
        留学优势:'Advantages of studying abroad',
        Study:'PROGRAMS',
        选择适合你的留学计划:"Choose a study abroad program that suits you",
        SERVICESYSTEM:'SERVICE SYSTEM',
        科学智能的服务体系:'Scientific and intelligent service system, comprehensively enhance your combat effectiveness in studying abroad',
        准备信息:'Prepare information',
        Prepareinformation:'Prepare information',
        香港本科申请流程:'Undergraduate application process',
        undergraduate:'APPLICATION PROCESS',
        Undergraduateadmissioncases:"OFFERS",
        本科录取案例:'Undergraduate admission cases',

    },
    graduateStudent:{//研究生
        联系我们:'Contact us',
        全套申请计划:'Complete application plan',
        直通车申请计划:'Through Train Application Program',
        半DIY申请计划:'Semi-DIY Application Program',
        适合人群:'Suitable for the crowd',
        项目特色:'Program features',
        留学咨询:"Program features",
        没有留学申请经验:'No experience in studying abroad, need time planning and application assistance',
        没有确定申请学校:'No school to apply for, school information and school selection analysis are required',
        英语语言能力不足:'Insufficient English language skills to communicate with admissions officers',
        想要提升背景:"A desire to enhance your background and apply to top universities",
        想要有专业老师把控整个申请流程:'Want a professional teacher to control the entire application process',
        想有导师全程规划考试及选课流程:'Want a tutor to plan the exam and course selection process',
        除求学外:'Apart from studying, there is also a need to stay in Hong Kong for internship and work',
        留学工作全指导:'Full guidance on studying abroad, ensuring employment according to demand',
        没量身打造独特背景提升增加竞争优势:'Tailor-made unique background to enhance competitive advantage',
        中美两国咨询老师全程把关助力申请:'Consultants in China and Hong Kong will be on hand to help you through the application process',
        已完成标准化考试 :"Completed standardized tests with sufficient time and energy",
        初步确定目标学校:'Preliminarily determine the target school and understand its application requirements',
        无法独立完成文书:'Unable to complete the document independently, need guidance and revision',
        希望获得专业指导:'Hope to get professional guidance and improve the success rate of admission',
        专业咨询老师评价学生背景:"Professional consultants evaluate students' backgrounds and provide school selection suggestions",
        专业文书老师指导创作思路:'Professional document teachers guide creative ideas and revise application documents',
        可灵活选择签注指导:'You can flexibly choose multiple services such as endorsement guidance, pre-departure counseling, etc.',
        详细的留学规划:'Detailed study abroad plan',
        基于企业内部庞大的数据库资料:"Based on the huge internal database of the enterprise, it combines the students' academic background, hobbies and future development plans, target colleges, majors, and curriculum settings. Provide the most suitable school selection and positioning plan.",
        全方位的背景提升:'All-round background enhancement',
        根据选校定位为学生匹配相关的背景提升项目和机会:'According to the selected school positioning, students are matched with relevant background improvement projects and opportunities, including internships in large factories, scientific research projects, study tour projects, etc. Improve the overall competitiveness of students in an all-round way.',
        优质的文书材料:'High-quality paperwork',
        文书顾问与学生进行头脑风暴:"The clerical consultant teacher brainstorms with the students and conducts one-to-one communication. Explore student highlights for different majors. Write personalized high-end version of the paper materials from the perspective of admissions officers, reject templated and stereotyped.",
        高效的笔试面试特训:'Efficient written test interview training',
        HK汇生会拥有丰富的本科:"HK Student Living has rich experience in interview counseling for undergraduate, master's and doctoral written examinations. It is guided by famous teachers from Hong Kong and Macao, and provides real mock interviews and professional detailed comments.",
        升学后的留学援助:'Study abroad assistance after entering university',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"The student apartment provided by the HK Student Living perfectly solves the accommodation problem of students studying in Hong Kong, and the academic counseling service provided enables students to successfully complete the study tasks during the study abroad stage.",
        学生姓名:'Student Name',
        申请类型:'Application Type',
        学术成绩:'Academic Score',
        英语成绩:'English grade',
        录取大学:'Admitted University',
        查看更多:'See more',
        研究生申请材料:'Postgraduate application materials',
        个人简历:'1、Personal basic materials (ID card, etc.)',
        推荐信:'2、Graduation certificate and degree certificate / proof of study',
        学术及实习科研类:'3、Transcript',
        个人陈述:'4、IELTS/TOEFL/CET 6 transcript',
        本科官方成绩单:'5、Work certificate or internship certificate',
        语言成绩单:'6、Award certificate (if any)',
        其他奖项:'7、Documentary materials (CV/RL/PS) ',
        科研:'8、Others',
        托福:'TOEFL',
        有海外英文授课本科背景可免:'（Overseas English-taught undergraduate background can be exempted）',
        一百加:'100+',
        港中科:'Hong Kong University/The Chinese University of Hong Kong/The Hong Kong University of Science & Technology',
        九十加:'90+',
        城理浸:'City University of Hong Kong/The Hong Kong Polytechnic University/Hong Kong Baptist University',
        八十五加:'85+',
        岭南教达:'Lingnan University/The Education University of Hong Kong',
        雅思:'IELTS',
        六点五到七:'6.5-7',
        六到六点五:'6-6.5',
        六加:"6+",
        部分专业需要:'Partially required',
        具体分数要求:'Specific score requirements',
        点击咨询:'Click to consult',
        GRE:'GRE/GMAT',
        Advantages:'ADVANTAGES',
        留学优势:'Advantages of studying abroad',
        Study:'PROGRAMS',
        选择适合你的留学计划:"Choose a study abroad program that suits you",
        SERVICESYSTEM:'SERVICE SYSTEM',
        科学智能的服务体系:'Scientific and intelligent service system, comprehensively enhance your combat effectiveness in studying abroad',
        准备信息:'Prepare information',
        Prepareinformation:'Prepare information',
        Postgraduate:'ENTRANCE REQUIREMENTS',
        研究生申请条件:'Postgraduate application conditions',
        PROCESSKEYPOINTS:'APPLICATION PERIOD',
        研究生申请流程:'Postgraduate Application Process',
        Graduateadmissioncases:"OFFERS",
        研究生录取案例:'Postgraduate admission cases',

    },
    overseasstudyDetail:{//香港留学详情
        发布时间:'Release time：',
        热度:"Heat：",
        在线咨询:'Online consultation',
        首页:'Home',
        港漂干货:'Study Abroad Guide',
        香港留学:'Study in Hong Kong',
        PortromptType:"Study in Hong Kong"
    },
    studentapartment:{//学生公寓
        请输入要搜索的房源:'Please enter the property you want to search',
        搜索:'Search',
        区域:'Area',
        学校:'School',
        租金:'Rent',
        租期类型:'Type of lease',
        房间类型:'Room type',
        性别要求:'Gender requirements',
        公寓列表:'Apartment List',
        月起:'Star in',
        不限:'unlimited',
        以上:'Above 9000',
        三千:'0-3000',
        五千:'3000-5000',
        七千:'5000-7000',
        九千:'7000-9000',
    },
    studentapartmentDetail:{//学生公寓详情
        简介:'Introduction',
        周边:'Surrounding',
        设施:'Facilities/Services',
        公寓信息:'Apartment Information',
        公寓名称:'Apartment name：',
        附近地铁:'Nearby subway：',
        附近大学:'Nearby University：',
        详细地址:'Detailed address：',
        租期类型:'Lease type：',
        房型:'Room type：',
        公寓设施:'Apartment Facilities',
        房间设施:'Room Facilities',
        公寓服务:'Apartment Services',
        租房须知:'Instructions for renting',
        预定流程:'Reservation process',
        管理服务费:'Management service fee',
        取消预定:'Cancel booking',
        月:'Month'
    },
    aboutUs:{//关于我们
        致力于提供香港:' Committed to providing the most professional one-stop services for studying abroad in countries or regions such as Hong Kong, Macau, the United Kingdom, Canada and foreign countries: including associate degree, undergraduate, postgraduate, doctoral and other professional applications, famous school through train programs, transfer applications, background enhancement, academic Tutoring, research projects, etc. We have first-hand Hong Kong institution data, Hong Kong school ranking information, course information, and study abroad life guide. Aiming to become a professional, high-quality and honest local service brand in Hong Kong, helping students to enter their desired institutions.',
        留学生温暖生活社区:'Warm international student living community',
        ivingcommunity:'Living community',
        汇生会成立于xxxx年:'汇生会成立于xxxx年， 是香港领先的xx平台。',
        同时我们致力于提供周全的学生生活服务:"At the same time, we are committed to providing comprehensive student life services: 'At the same time, we are committed to providing comprehensive student life services: including student apartments, pick-up services, house inspections, home cleaning, express delivery, etc. The choice of student apartments provides a safe, high-quality and comfortable living environment for international students in Hong Kong. At present, we have operated more than 150 student apartments around major colleges and universities, including full-scale properties, and the cumulative number of students has exceeded 1,000.",
        是香港领先拥有留学:"It is Hong Kong's leading one-stop platform for overseas study and student apartment services",
        录取成功率:'Admission success rate is 90%',
        公寓累积服务人数过千位学生:'The cumulative number of students served by the apartment exceeds 1,000',
        超过5年高端留学申请经验:'Over 5 years of high-end study abroad application experience',
        专业顾问团队:'Professional consultant team',
        括名校导师:'(including famous school tutors/former admissions officers as consultants)',
        为何需要升学顾问:'Why do I need a counselor',
        Whydo:'Why do I need a counselor',
        省时:'Time saving & professional',
        到外地留学并不是填好表格送出就完事了:"Studying abroad is not done by filling in the form and sending it out. If you don't consider your future development and only see a good evaluation of a school and then submit your application for admission, it is very possible Will waste your time and money.",
        升学顾问能从每个学校的特点:" Admission consultants can have a detailed understanding of the characteristics of each school, professional perspectives, and admission requirements, and refer to the data of successful cases. ",
        在背景提升方面:' In terms of background improvement, whether it is professional courses or English improvement, the consultant can recommend some training courses with better experience for previous students',
        往往留学文书材料是学生联系我们的主要原因:'The study abroad documents are often the main reason for students to contact us. Writing is an art. In order to allow students to enter the university of their choice, we will help students to explore themselves as much as possible, so that The content of the essay is more appropriate to what the admissions officer wants to see.',
        联系我们:'Contact us',//修改
        客服热线:'Customer Service Hotline',
        微信公众号:'WeChat Official Account',
        联系地址:'Contact address',
        在线留言:'Online message',
        姓名:'Name',
        联系方式:'Contact number',
        邮箱:'Email',
        内容:'Content',
        提交:'Summit',
        请填写联系方式:"Please fill in the contact information",
        提交成功:'Submitted successfully',
        已提交:'Submitted',
        一站式留学:'One-stop study abroad',
        OverseasStudy:'Overseas Study',
        contactus:'Contact Us',//修改
    },
    admission:{//录取捷报
        首页:'Home',
        录取捷报:'SUCCESS OFFER',
        副学士:'Associate Degree',
        本科:'Undergraduate',
        研究生:'Postgraduate',
        已提交:'Submitted',
        AdmissionsuccessReport:'Success Offers',
        APPLICATIONPROCESS:"PARTNERS",
        支持:'Support/Cooperative institutions',

    },
    admissionDeatil:{//录取捷报详情
        首页:'Home',
        录取捷报:'SUCCESS OFFER',
        AdmissionsuccessReport:'Admission success Report',
        APPLICATIONPROCESS:"PARTNERS",
        支持:'Support/Cooperative institutions',
        发布时间:"Release time:",
        热度:"Heat：",
        在线咨询:'Online consultation',
    },
    hkgoods:{
        首页:'Home',
        港漂干货:'Study Abroad Guide',
        APPLICATIONPROCESS:"PARTNERS",
        支持:'Support/Cooperative institutions',
        Portdriftdrycargo:'News'
    },
    Famousschool:{//名校直通车
        HK汇生会的名校直通车计划:'The elite school through train program of the HK Student Living has helped many students with poor backgrounds to enter the elite school smoothly. The program is assisted by senior personnel from the education sector in Hong Kong and Macau to participate in the whole process, providing students with professional and high-quality education consulting services and further education plans, helping students to improve their backgrounds and preparing application materials, and provide professional written test interview coaching training and related support services in the later stage. To enable students to successfully obtain the admission results of famous schools, and at the same time to realize the promise of full refund if unsuccessful.',
        计划合适人群本科:'Program suitable population (undergraduate)',
        不想读预科:'1. Do not want to study preparatory courses, international freshmen and other bridge courses',
        预科在读难以通过:'2. It is difficult to pass the preparatory course, and I want to go straight to the freshman year',
        语言成绩不达标:'3. The language score is not up to standard',
        计划合适人群研究生:'Program Suitable Population (Postgraduate students)',
        本科院校背景较差:'1. Poor undergraduate background',
        GPA偏低:'2. Low GPA',
        软能力不足:'4. Insufficient soft ability and zero practical experience',
        国内考研失利后想入读名校:'5. After failing the domestic postgraduate entrance examination, I want to enter a famous school',
        详细的留学规划:"Detailed study abroad plan",
        基于企业内部庞大的数据库资料:" Based on the huge internal database of the enterprise, it combines the students' academic background, hobbies and future development plans, target colleges, majors, and curriculum settings. Provide the most suitable school selection and positioning plan.",
        全方位的背景提升:'All-round background enhancement',
        根据选校定位为学生匹配相关的背景提升项目和机会:'According to the selected school positioning, students are matched with relevant background improvement projects and opportunities, including internships in large factories, scientific research projects, study tour projects, etc. Improve the overall competitiveness of students in an all-round way',
        优质的文书材料:'High-quality paperwork',
        文书顾问与学生进行头脑风暴:'The clerical consultant teacher brainstorms with the students and conducts one-to-one communication. Explore student highlights for different majors. Write personalized high-end version of the paper materials from the perspective of admissions officers, reject templated and stereotyped.',
        高效的笔试面试特训:'Efficient written test interview training',
        HK汇生会拥有丰富的本科:"HK Student Living has rich experience in interview counseling for undergraduate, master's and doctoral written examinations. It is guided by famous teachers from Hong Kong and Macao, and provides real mock interviews and professional detailed comments.",
        升学后的留学援助:'Study abroad assistance after entering university',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:'The student apartment provided by the HK Student Living perfectly solves the accommodation problem of students studying in Hong Kong, and the academic counseling service provided enables students to successfully complete the study tasks during the study abroad stage.',
        香港本土高端留学机:'Local high-end study abroad institutions in Hong Kong',//修改
        年高端申请经验:'6 years of high-end application service experience',//修改
        海量名校录取案例:'Massive famous school admission case',
        服务模式:'5+1 Service Mode',
        老师服务:'5 teachers serve 1 student',
        包括香港本土老师和名校顾问:"Including Hong Kong local teachers and famous school consultants",
        对申请结果负责:'Responsible for application results',
        录取成功率:'95% admission success rate',//修改
        录取率:'90% dream school acceptance rate',//修改
        一站式留学服务:'Comprehensive assistance services after further studies',
        提供完善的接机:'Provide comprehensive visa guidance, accommodation arrangements, academic counseling, internship job planning and other services',
        学生姓名:'Student name：',
        申请类型:'Application Type：',
        学术成绩:'Academic Score：',
        英语成绩:'English grade：',
        录取大学:'Admitted University：',
        查看更多:'See more',
        Studyabroadprogram:'DIRECT PATH TO DREAM SCHOOL',
        名校直通车计划优势:"Advantages of the famous school through train plan",
        直通车计划服务流程:'Through train plan service process',
        Study:'Study abroad program',
        ADVANTAGE:'ADVANTAGE',
        选择智优:'Choose HK Student Living and helping students gain admission to their dream school',
        录取捷报:"SUCCESS OFFER",
        SUCCESSOFFER:'OFFER',
        OverseasStudy:'Overseas Study',
        一站式留学:'One-stop study abroad',
        SERVICESYSTEM:'SERVICE SYSTEM',
        科学智能的服务体系:"Scientific and intelligent service system, comprehensively enhance your combat effectiveness in studying abroad",
        选择汇生会:"Choose HK Student Living, let Hong Kong's rich tutor resources be used by you",
        优质的定制化文书材料:'High-quality customized documents',
        一站式服务流程:'Senior study abroad service team',//新增
        专业资深导师团队:'Professional senior mentor team',//新增
        包括香港本地教授助阵:'Include local professors from Hong Kong to help out',//新增
        海量成功案例:'Massive success case',//新增
        升学后的全方位援助服务:'All-round assistance service after entering higher education',//新增
        提供专业的住宿服务:'Provide professional accommodation services, academic counseling and career guidance planning',//新增
    },
    poplypop:{
        汇生汇:'Student Living',//修改
        香港:'Hong Kong',
        文化科技有限公司:'Culture Technology Ltd.',
        深圳:'Shen Zhen',
        姓名:'Name',
        请输入:'Please enter',
        性别:'Gender',
        留学申请:'Application for studying abroad',
        微信号:'WeChat ID',
        提交:'Submit',
        男:'Male',
        女:'Female',
        副学士:'Associate Degree',
        本科:'Undergraduate',
        研究生:'Postgraduate'
    },
    poplypoptwo:{
        汇生汇:'Student Living',//修改
        香港:'Hong Kong',
        文化科技有限公司:'Culture Technology Ltd.',
        深圳:'Shen Zhen',
        姓名:'Name',
        准备就读院校:'Preferred Schools',
        请输入目前:'Please enter your current/preferred Schools',
        请选择:'Please select',
        请输入:'Please enter',
        性别:'Gender',
        留学申请:'Application for studying abroad',
        微信号:'WeChat ID',
        提交:'Submit',
        男:'Male',
        女:'Female',
        其他:'Other'
    },
    dialog:{
        汇生汇:'Student Living',//修改
        香港:'Hong Kong',
        文化科技有限公司:'Culture Technology Ltd.',
        深圳:'Shen Zhen',
        留言内容:'Message content',
        姓名:'Name',
        电话:'Contact number (required)',
        地址:'Address',
        微信:'WeChat ID',
        请输入:'Please enter',
        提交:'Submit',
        请填写手机号:'Please fill in mobile number'
    },
    erweimaDialog:{
        汇生汇:'Student Living',//修改
        香港:'Hong Kong',
        文化科技有限公司:'Culture Technology Ltd.',
        深圳:'Shen Zhen',
        联系我们:'Contact us',
        了解更多:'See more'
    },
    ielts:{
        评分标准:'Grading',
        总分:"Total score: 9 points",
        选择模式:'Select mode',
        考试模式:'Exam Mode',
        考试内容:'Examination Content',
        考试流程:'Exam process',
        报名程序:'Registration procedure',
        考后服务:'After-exam service',
    },
    toefl:{
        考试形式:'Exam Format',
        退考与转考:"Withdrawal and Transfer",
        成绩复议:'Reconsideration of grades',
        考试流程:'Exam process',
    },
    scientificResearch:{
        论文修改:'Paper revision — polishing for publication',
        Paper:'Paper revision — polishing for publication',
        项目流程:'Project Flow',
        导师根据学生原有论文提出修改建议:"The tutor proposes revision suggestions based on the students' original papers, and explains the revision suggestions for 1 hour fixedlyAfter the students make preliminary revisions independently, the tutor will polish and finalize the draft.",
        适合学生:'Suitable for students',
        适合已有论文的学生:'Suitable for students with existing papers',
        项目导师:'Project Mentor',
        双一流名校博士:'Doctors, postdoctoral fellows, and university lecturers from two first-class universities',
        学术成果:'Academic Achievements',
        综述型论文:'Review paper',
        发表级别:'Posting level',
        国际会议论文:'CPCI/E (International Conference Papers)',
        项目收获:'Project harvest',
        修改完善的论文:'Revised and perfected thesis Dissertation acceptance notice',
        综述型论文辅导:'Literature Review Tutoring',
        Literature:'Literature Review Tutoring',
        项目周期:'Project Cycle',
        包含12课时授课:'12 months, including 12 hours of teaching (reference) + unlimited Q&A after class',
        适合全部学科:'Suitable for all subjects and students with strong willingness to learn',
        理工:"cience and engineering, social science, business research filled papers, with research models (or experiments) 10 pages Zuogu (nearly 10,000 words) papers",
        国际会议录用:'Accepted by CPCI/EI International Conference',
        篇独作的论文:'1 self-authored essay；Mentor recommendation letter',
        录用通知:'SCl Acceptance Notice；Complete research experience',
        核心型论文辅导:'Core Thesis Tutoring',
        Core:'Core Thesis Tutoring',
        结果导向:'Result-oriented, unlimited number of tutoring and answering questions',
        本科及以上学生:'Undergraduate and above students need core journal publishing experience',
        代表性案例:'Representative Cases · Student Stories',
        学习方向:'Learning direction: Psychology',
        高中学校:'High School: Shenzhen Yew Wah Experimental School',
        学生成绩:'Student Score: TOEFL 101, SAT 1480',
        课题名称:" Project Title: Explore the Impact of Costs and Costs on People's Selfless Helping Behavior",
        Matt钟情于心理学专业:'Matt loves psychology major and the target school is Reed College. But in the general TOEFL 110, SAT1500 competitors, still feel the pressure. When Matt looked through the school information, he also found that psychology needs to do a lot of research in the follow-up study. So Matt decided to do a related scientific research project in advance, in order to gain the favor of the dream school.',
        在确认了心理学的大方向之后:'After confirming the general direction of psychology, under the advice of the instructor, the subject of the intersection of psychology and economics was selected, which made psychological research have a new foothold. Mentors give careful guidance, open questions, read literature, interviews, collect data, integrate analysis, and write papers. Along the way, you will face different challenges every day, and feel the unique charm of scientific research. Finally, the paper was successfully published in the international CPCI conference journal, and was invited to participate in ICSSH',
        位于京都的会议:'2017 Conference in Kyoto. With fruitful scientific research results, Matt has strengthened his belief in the field of psychology. The scientific research paper project is the material that can best prove academic ability other than grades. He wrote this experience in the application document, showing his love for the target major and academic potential, and the final version was rewarded with Reed',
        College:' College, Macalester College, Lafayette College and other 13 schools offered offers, and finally confirmed to enter the Reed Collese College to start a new journey of exploration. The good news is that in November 2017, Matt took advantage of his high school research experience to become a student who entered the Leaming & Adaptive Behav/ior Laboratory of Reed College as a freshman. In December 2018, Matt was in Chicago, and received a scholarship from Reed College to attend the Association for Behavior Analysis International (ABAI).',
        EI源刊:'CPCI/E (International Conference Papers)',
        导师推荐信:'1 self-authored essay；Mentor recommendation letter',
        完整的科研经历:'SCl Acceptance Notice；Complete research experience',
        Projectoverview:'OVERVIEW',
        项目概述:"Project overview",
        Productintroduction:'PRODUCTS',
        产品简介:'Product introduction',
        Studentstory:'Student story',
        MattWan:'Matt Wan',

    },
    interrnshipprogram:{
        strength:'STRENGTHES',
        实力覆盖行业:'trength in covering the industry',
        Suitableforpeople:'CUSTOMERS',
        适合人群:'Suitable for the crowd',
        internship:'INTERNSHIP',
        热门实习介绍:'Introduction to Popular Internships',
        合作企业:"Cooperative companies",
        Suitableforpeoples:'PARTNERS',
    },
    coach:{
        Entered:"OFFER LIST",
        已经进入的名企列表:"Entered list of famous enterprises",
        服务流程:'Service process',
        Serviceprocess:'Service process',
        Successstories:'SUCCESSFUL CASES',
        成功案例:'Success stories',
    },
    paper:{
        Projectprocess:'SERVICES',
        项目流程:'Project process',
        Thesiscounselingprocess:'GUIDANCE PROCESS',
        论文辅导流程:'Thesis counseling process',
        Coachingprocess:'GUIDANCE PROCESS',
        Essay辅导流程:"Essay coaching process",
        Servicehighlights:"STRENGTHES",
        服务亮点:'Service highlights',
        服务保障:'Service guarantee',
        Serviceguarantee:'SERVICE GUARANTEE',
    },
    tutor:{
        Famousteacherguidance:'PROFESSIONAL GUIDANCE',
        名师辅导:'Famous teacher guidance',
        Coachingprocess:'PROCESS',
        辅导流程:'Coaching process',
        Serviceadvantage:'STRENGTHES',
        服务优势:'Service advantage'
    },
    studyabroad:{
        Servicecontent:'Service content',
        笔试面试服务内容:'Written test/interview service content',
        Serviceadvantage:'Service advantage',
        服务优势:'Service advantage',
        Serviceprocess:'Service process',
        服务流程:'Service process',
    },
    entrance:{
        首页:'Home > Hong Kong Universities',
        PortromptType:"UNIVERSITIES",
        APPLICATIONPROCESS:'PARTNERS',
        支持:'Support/Cooperative institutions',
    },
    join:{
        实习岗位:'Internship positions',
        全职岗位:'full-time position'
    }
  };
  