// 繁体
export default {
    tophead: {
        商城: "商城",
        名校直通车:"名校直通車",
        学生公寓:'學生公寓',
        港漂干货:'港漂乾貨',
        成功案例:'成功案例',
        首页:"首頁",
        背景提升:"技能提升",//修改
        香港留学:"香港留學",
        院校入口:"院校入口",
        关于我们:"關於我們",
        语言提升:'語言提升',
        学术:'學術',
        副学士:'副學士',
        本科:'本科',
        研究生:'研究生',
        香港文化科技有限公司:'（香港）文化科技有限公司',//修改
        深圳文化科技有限公司:'（深圳）文化科技有限公司',//修改
        汇生会:'滙生活',
        香港:'香港',
        深圳:'深圳',
        文化科技有限公司:'文化科技有限公司',
        我是标题:'我是標題'
    },
    footer:{
        加入我们:"加入我們",
        联系电话:'聯繫電話',
        地址:'地址',
        小红书:'小紅書',
        公众号:'公眾號',
        咨询号:'諮詢號',
        汇生会:'滙生活',//修改
        香港:'香港',
        深圳:'深圳',
        文化科技有限公司:'文化科技有限公司',
    },
    homepage:{//首页
        留学申请计划:'留學申請計劃',
        副学士:'（副學士/本科/研究生/博士）',
        留学文书创作:'留學文書創作',
        为您提供有效的学习规划:'為您提供有效的學習規劃',
        留学面试辅导:'留學面試輔導',
        帮您逐一攻克面试流程:'幫您逐一攻克面試流程',
        背景提升室:'背景提升室',
        全方位提高竞争力:'全方位提高競爭力',
        名校直通车:'名校直通車',
        学校直录:'學校直錄，圓夢名校',
        香港学生公寓:'香港學生公寓',
        给你一个不一样的家:'給你一個不一樣的家',
        服务精选:"服務精選",
        HK汇生会:'HK滙生會',
        支持:"支持/合作機構",
        Serviceselection:'SERVICES',
        Strengthes:'STRENGTHES',
        APPLICATIONPROCESS:'PARTNERS',
    },
    background:{//背景提升
        IELTS:'IELTS',
        TOEFL:'TOEFL',
        GRE:'GRE',
        EMET:'GMAT',
        实习项目:'實習項目',
        科研项目:'科研項目',
        求职辅导:'求職輔導',
        论文辅导:'論文輔導',
        课程辅导:'課程輔導',
        留学面试辅导:'留學面試輔導',
        提示类型:'提升類型',
        PromptType:"Prompt Type",
    },
    overseasStudy:{//香港留学
        联系我们:'聯繫我們',
        全套申请计划:'全套申請計劃',
        直通车申请计划:'直通車申請計劃',
        半DIY申请计划:'半DIY申請計劃',
        适合人群:'適合人群',
        项目特色:'項目特色',
        留学咨询:"留學諮詢",
        没有留学申请经验:'沒有留學申請經驗，需要時間規劃和申請輔助',
        没有确定申请学校:'沒有確定申請學校，需要學校信息和選校分析',
        英语语言能力不足:'英語語言能力不足，不能與招生官無障礙溝通',
        想要提升背景:"想要提升背景，衝刺申請頂級名校",
        想要有专业老师把控整个申请流程:'想要有專業老師把控整個申請流程',
        想有导师全程规划考试及选课流程:'想有導師全程規劃考試及選課流程',
        除求学外:'除求學外，還有留港實習工作需求',
        留学工作全指导:'留學工作全指導，根據需求保證就業',
        没量身打造独特背景提升增加竞争优势:'量身打造獨特背景提升增加競爭優勢',
        中美两国咨询老师全程把关助力申请:'中國內地和香港兩地諮詢老師全程把關助力申請',
        已完成标准化考试 :"已完成標準化考試，時間和精力充足",
        初步确定目标学校:'初步確定目標學校，了解其申請要求',
        无法独立完成文书:'無法獨立完成文書，需要指導與修改',
        希望获得专业指导:'希望獲得專業指導，提高錄取成功率',
        专业咨询老师评价学生背景:'專業諮詢老師評價學生背景，提供選校建議',
        专业文书老师指导创作思路:'專業文書老師指導創作思路，修改申請文書',
        可灵活选择签注指导:'可靈活選擇簽注指導、行前輔導等多項服務',
        详细的留学规划:'詳細的留學規劃',
        基于企业内部庞大的数据库资料:"基於企業內部龐大的數據庫資料，同時結合學生的學術背景、興趣愛好和未來的發展規劃，目標院校及專業，專業的課程設置等進行最合適的選校定位規劃。",
        全方位的背景提升:'全方位的背景提升',
        根据选校定位为学生匹配相关的背景提升项目和机会:'根據選校定位為學生匹配相關的背景提升項目和機會，包括大廠實習、科研項目、遊學項目等，全方位提升學生的整體競爭力。',
        优质的文书材料:'優質的文書材料',
        文书顾问与学生进行头脑风暴:"文書顧問與學生進行頭腦風暴，一對一溝通，針對不同專業定制化挖掘學生亮點，從招生官角度撰寫個性化高定版的文書材料，拒絕模板化及千篇一律。",
        高效的笔试面试特训:'高效的筆試面試特訓',
        HK汇生会拥有丰富的本科:'HK滙生會擁有豐富的本科、碩士、博士筆試面試 輔導經驗，由港澳專業名師指導，提供全真模擬面試及專業詳細的點評。',
        升学后的留学援助:'升學後的留學援助',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"HK滙生會提供的學生公寓完美解決了學生在港留學的住宿問題，提供的學術輔導服務讓學生能夠順 利完成留學階段的學習任務。",
        美解决了学生在港留学的住宿问题:"HK滙生會提供的學生公寓完美解決了學生在港留學的住宿問題，提供的學術輔導服務讓學生能夠順利完成留學階段的學習任務。",
    申请前:'申請前',
    Beforeapplication:"Before application",
    选校定位:'選校定位',
    选校分析:'選校分析',
    方案定位:'方案定位',
    留学策略定制:'留學策略定制',
    个月VIP定制私人留学顾问:'12個月VIP定制私人留學顧問',
    名师实时咨询:'名師實時諮詢',
    选校方案定位:'選校方案定位',
    申请策略定制:'申請策略定制',
    申请材料指导:'申請材料指導',
    网申课程全覆盖:'網申課程全覆蓋',
    申请中:'申請中',
    Inapplication:'In application',
    文书材料:'文書材料',
    全套文书:'全套文書',
    CV:'CV',
    RS:"RS",
    R2:'R2',
    PCE:'PCE',
    文书翻译:'文書翻譯',
    高定文书:'高端文書',
    颜色:'顏色',
    网申助手:'網申助手',
    网申管家:'網申管家',
    面试指导:'面試指導',
    申请后:'申請後',
    application:'application',
    签证指导:'簽證指導',
    落地服务:'落地服務',
    海外租房:'海外租房',
    留学迎新礼包:'留學迎新禮包',
    香港本土高端留学机:"香港本土高端留學機構",//修改
    年高端申请经验:"15年高端申請經驗",
    海量名校录取案例:'海量名校录取案例',
    服务模式:"5+1服務模式",
    位老师服务:'5位老師服務1名學生',
    包括香港本土老师和名校顾问:'包括香港本土老師和名校顧問',
    对申请结果负责:'對申請結果負責',
    录取成功率:"95%錄取成功率",//修改
    录取率:"90%目標名校錄取率",//修改
    一站式留学服务:"一站式留學服務",
    提供完善的接机:'提供完善的簽證指導、住宿安排、學術輔導、實習求職規劃等服務',
    学生姓名:'學生姓名',
    申请类型:'申請類型',
    学术成绩:'學術成績',
    英语成绩:'英語成績',
    录取大学:'錄取大學',
    查看更多:'查看更多',
    Advantages:'ADVANTAGES',
    留学优势:'留學優勢',
    Study:'PROGRAMS',
    选择适合你的留学计划:'選擇適合你的留學計劃',
    SERVICE:'SERVICE SYSTEM',
    科学智能的服务体系:"科學智能的服務體系，全面提升你的留學戰鬥力",
    Prepare:'Prepare information',
    准备信息:'準備信息',
    汇生汇留学服务:'HK滙生會留學服務，幫你逐一攻克申請流程關鍵點',//修改
    PROCESS:'PROCESS KEY POINTS',
    ADVANTAGE:'ADVANTAGE',
    选择汇生汇:'選擇滙生會，讓香港豐富導師資源為你所用',//修改
    录取捷报:'錄取捷報',
    SUCCESSOFFER:"OFFER",
    APPLICATION:'APPLICATION PROCESS',
    支持:'支持/合作機構',
    多种留学方案匹配不同人群:'多種留學方案匹配不同人群',
    对选校定位及留学申请流程不清楚:"對選校定位及留學申請流程不清楚",//新增
    缺少时间精力自行准备申请材料:'缺少時間精力自行準備申請材料',//新增
    根据学生条件提供定制式留学方案:'根據學生條件提供定制式留學方案',//新增
    提供准确的申请时间规划:'提供準確的申請時間規劃',//新增
    提供详细全面的申请材料指导:'提供詳細全面的申請材料指導',//新增
    根据相关专业和面试要求提供面试服务和指引材料:"根據相關專業和麵試要求提供面試服務和指引材料",//新增
    本科院校背景较差:'本科院校背景較差',//新增
    GPA及语言成绩偏低:'GPA及語言成績偏低',//新增
    软实力不足:'軟實力不足，缺乏實踐經驗',//新增
    国内考研失利后想入读名校:'國內考研失利後想入讀名校',//新增
    从前期咨询到成功入学全程一站式服务收获更高性价比:'從前期諮詢到成功入學全程一站式服務收穫更高性價比',//新增
    资深教育界专家顾问:'資深教育界專家顧問，一對一筆試面試專業指導',//新增
    提供独家资源:"提供獨家資源，全程跟踪服務，保證最終學生順利入學",//新增
    有明确的选校定位:"有明確的選校定位",//新增
    主动性较强且有清晰的申请规划:"主動性較強且有清晰的申請規劃",//新增
    英语阅读及写作能力较强:'英語閱讀及寫作能力較強',//新增
    针对学生需求提供细分服务:'針對學生需求提供細分服務（包括文書服務，網申指導等）',//新增
    拒绝捆绑式销售:'拒絕捆綁式銷售，高性價比',//新增
    有效沟通:'有效溝通，提供專業性指導',//新增
    During:"During application",//新增
    After:'After application',//新增
    个人陈述:"个人陈述（PS）",//新增
    简历:'簡歷（CV）',//新增
    推荐信:"推薦信（RL）",//新增
    其他:'其他（essay等）',//新增
    选择汇生会:"選HK匯生會，助力學生斬獲夢校錄取",//新增
    年高端申请服务经验:"6年高端申請服務經驗",//新增
    优质的定制化文书材料:'優質的定制化文書材料',//新增
    一站式服务流程:'資深留學服務團隊',//新增
    专业资深导师团队:'專業資深導師團隊',//新增
    包括香港本地教授助阵:'包括香港本地教授助陣',//新增
    海量成功案例:'海量成功案例',//新增
    升学后的全方位援助服务:'升學後的全方位援助服務',//新增
    提供专业的住宿服务:'提供專業的住宿服務、學術輔導及就業指導規劃',//新增
        副学士:'副學士',
        本科:"本科",
        研究生:'研究生',
        副学士升学途径:"副學士升學途徑",
},
associatedegree:{//副學士
    联系我们:'聯繫我們',
        全套申请计划:'全套申請計劃',
        直通车申请计划:'直通車申請計劃',
        半DIY申请计划:'半DIY申請計劃',
        适合人群:'適合人群',
        项目特色:'項目特色',
        留学咨询:"留學諮詢",
        没有留学申请经验:'沒有留學申請經驗，需要時間規劃和申請輔助',
        没有确定申请学校:'沒有確定申請學校，需要學校信息和選校分析',
        英语语言能力不足:'英語語言能力不足，不能與招生官無障礙溝通',
        想要提升背景:"想要提升背景，衝刺申請頂級名校",
        想要有专业老师把控整个申请流程:'想要有專業老師把控整個申請流程',
        想有导师全程规划考试及选课流程:'想有導師全程規劃考試及選課流程',
        除求学外:'除求學外，還有留港實習工作需求',
        留学工作全指导:'留學工作全指導，根據需求保證就業',
        没量身打造独特背景提升增加竞争优势:'沒量身打造獨特背景提升增加競爭優勢',
        中美两国咨询老师全程把关助力申请:'中國內地和香港諮詢兩地老師全程把關助力申請',
        已完成标准化考试 :"已完成標準化考試，時間和精力充足",
        初步确定目标学校:'初步確定目標學校，了解其申請要求',
        无法独立完成文书:'無法獨立完成文書，需要指導與修改',
        希望获得专业指导:'希望獲得專業指導，提高錄取成功率',
        专业咨询老师评价学生背景:'專業諮詢老師評價學生背景，提供選校建議',
        专业文书老师指导创作思路:'專業文書老師指導創作思路，修改申請文書',
        可灵活选择签注指导:'可靈活選擇簽注指導、行前輔導等多項服務',
        详细的留学规划:'詳細的留學規劃',
        基于企业内部庞大的数据库资料:"基於企業內部龐大的數據庫資料，同時結合學生的學術背景、興趣愛好和未來的發展規劃，目標院校及專業，專業的課程設置等進行最合適的選校定位規劃。",
        全方位的背景提升:'全方位的背景提升',
        根据选校定位为学生匹配相关的背景提升项目和机会:'根據選校定位為學生匹配相關的背景提升項目和機會，包括大廠實習、科研項目、遊學項目等，全方位提升學生的整體競爭力。',
        优质的文书材料:'優質的文書材料',
        文书顾问与学生进行头脑风暴:"文書顧問與學生進行頭腦風暴，一對一溝通，針對不同專業定制化挖掘學生亮點，從招生官角度撰寫個性化高定版的文書材料，拒絕模板化及千篇一律。",
        高效的笔试面试特训:'高效的筆試面試特訓',
        HK汇生会拥有丰富的本科:'HK滙生會擁有豐富的本科、碩士、博士筆試面試 輔導經驗，由港澳專業名師指導，提供全真模擬面試及專業詳細的點評。',
        升学后的留学援助:'升學後的留學援助',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"HK滙生會提供的學生公寓完美解決了學生在港留學的住宿問題，提供的學術輔導服務讓學生能夠順 利完成留學階段的學習任務。",
        内地高考成绩:'內地高考成績',
        面试成绩:'面試成績',
        达二本线或以上:'達二本線或以上',
        国际生成绩:'國際生成績',
        HKDSE:"HKDSE",
        SAT:'SAT',
        LEVEL:'A-LEVEL',
        IB:'IB',
        考试成绩:'考試成績',
        BETC:'BETC-HND等級',
        正规国际高中成绩单:'正規國際高中成績單',
        高考英语单科成绩达90分:'高考英語單科成績達90分',
        满分150分制:'（滿分150分制）或雅思5.5',
        部分专业需要:'部分專業需要',
        具体分数要求:'具體分數要求',
        点击咨询:'點擊諮詢',
        学生姓名:'學生姓名',
        申请类型:'申請類型',
        学术成绩:'学术成绩',
        英语成绩:'英語成績',
        录取大学:'錄取大學',
        查看更多:'查看更多',
        Advantages:'ADVANTAGES',
        留学优势:'留學優勢',
        Study:'PROGRAMS',
        选择适合你的留学计划:'選擇適合你的留學計劃',
        SERVICESYSTEM:'SERVICE SYSTEM',
        科学智能的服务体系:'科學智能的服務體系，全面提升你的留學戰鬥力',
        准备信息:'準備信息',
        Prepareinformation:'Prepare information',
        Introduction:'INTRODUCTIONs',
        副学位课程介绍:'副學士課程介紹',
        Application:"ENTRANCE REQUIREMENTS",
        Applications:"APPLICATION PERIOD",
        副学士申请条件:'副學士申請條件',
        副学士申请时间流程:'副學士申請時間流程',
        Associate:'OFFERS',
        副学士录取案例:'副學士錄取案例',
        副学士升学途径:"副學士升學途徑",//新增
        AssociateDegreeProgressionPathway:"ASSOCIATE DEGREE",//新增
},
undergraduate:{
    联系我们:'聯繫我們',
        全套申请计划:'全套申請計劃',
        直通车申请计划:'直通車申請計劃',
        半DIY申请计划:'半DIY申請計劃',
        适合人群:'適合人群',
        项目特色:'項目特色',
        留学咨询:"留學諮詢",
        没有留学申请经验:'沒有留學申請經驗，需要時間規劃和申請輔助',
        没有确定申请学校:'沒有確定申請學校，需要學校信息和選校分析',
        英语语言能力不足:'英語語言能力不足，不能與招生官無障礙溝通',
        想要提升背景:"想要提升背景，衝刺申請頂級名校",
        想要有专业老师把控整个申请流程:'想要有專業老師把控整個申請流程',
        想有导师全程规划考试及选课流程:'想有導師全程規劃考試及選課流程',
        除求学外:'除求學外，還有留港實習工作需求',
        留学工作全指导:'留學工作全指導，根據需求保證就業',
        没量身打造独特背景提升增加竞争优势:'沒量身打造獨特背景提升增加競爭優勢',
        中美两国咨询老师全程把关助力申请:'中國內地和香港兩地諮詢老師全程把關助力申請',
        已完成标准化考试 :"已完成標準化考試，時間和精力充足",
        初步确定目标学校:'初步確定目標學校，了解其申請要求',
        无法独立完成文书:'無法獨立完成文書，需要指導與修改',
        希望获得专业指导:'希望获得专业指导，提高录取成功率',
        专业咨询老师评价学生背景:'專業諮詢老師評價學生背景，提供選校建議',
        专业文书老师指导创作思路:'專業文書老師指導創作思路，修改申請文書',
        可灵活选择签注指导:'可靈活選擇簽注指導、行前輔導等多項服務',详细的留学规划:'詳細的留學規劃',
        基于企业内部庞大的数据库资料:"基於企業內部龐大的數據庫資料，同時結合學生的學術背景、興趣愛好和未來的發展規劃，目標院校及專業，專業的課程設置等進行最合適的選校定位規劃。",
        全方位的背景提升:'全方位的背景提升',
        根据选校定位为学生匹配相关的背景提升项目和机会:'根據選校定位為學生匹配相關的背景提升項目和機會，包括大廠實習、科研項目、遊學項目等，全方位提升學生的整體競爭力。',
        优质的文书材料:'優質的文書材料',
        文书顾问与学生进行头脑风暴:"文書顧問與學生進行頭腦風暴，一對一溝通，針對不同專業定制化挖掘學生亮點，從招生官角度撰寫個性化高定版的文書材料，拒絕模板化及千篇一律。",
        高效的笔试面试特训:'高效的筆試面試特訓',
        HK汇生会拥有丰富的本科:'HK滙生會擁有豐富的本科、碩士、博士筆試面試 輔導經驗，由港澳專業名師指導，提供全真模擬面試及專業詳細的點評。',
        升学后的留学援助:'升學後的留學援助',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"HK滙生會提供的學生公寓完美解決了學生在港留學的住宿問題，提供的學術輔導服務讓學生能夠順 利完成留學階段的學習任務。",
        学生姓名:'學生姓名',
        申请类型:'申請類型',
        学术成绩:'學術成績',
        英语成绩:'英語成績',
        录取大学:'錄取大學',
        查看更多:'查看更多',
        Advantages:'ADVANTAGES',
        留学优势:'留學優勢',
        Study:'PROGRAMS',
        选择适合你的留学计划:"選擇適合你的留學計劃",
        SERVICESYSTEM:'SERVICE SYSTEM',
        科学智能的服务体系:'科學智能的服務體系，全面提升你的留學戰鬥力',
        准备信息:'準備信息',
        Prepareinformation:'Prepare information',
        香港本科申请流程:'香港本科申請流程',
        undergraduate:'APPLICATION PROCESS',
        Undergraduateadmissioncases:"OFFERS",
        本科录取案例:'本科錄取案例',

},
graduateStudent:{//研究生
    联系我们:'聯繫我們',
        全套申请计划:'全套申請計劃',
        直通车申请计划:'直通車申請計劃',
        半DIY申请计划:'半DIY申請計劃',
        适合人群:'適合人群',
        项目特色:'項目特色',
        留学咨询:"留學諮詢",
        没有留学申请经验:'沒有留學申請經驗，需要時間規劃和申請輔助',
        没有确定申请学校:'沒有確定申請學校，需要學校信息和選校分析',
        英语语言能力不足:'英語語言能力不足，不能與招生官無障礙溝通',
        想要提升背景:"想要提升背景，衝刺申請頂級名校",
        想要有专业老师把控整个申请流程:'想要有專業老師把控整個申請流程',
        想有导师全程规划考试及选课流程:'想有導師全程規劃考試及選課流程',
        除求学外:'除求學外，還有留港實習工作需求',
        留学工作全指导:'留學工作全指導，根據需求保證就業',
        没量身打造独特背景提升增加竞争优势:'沒量身打造獨特背景提升增加競爭優勢',
        中美两国咨询老师全程把关助力申请:'中國內地和香港兩地諮詢老師全程把關助力申請',
        已完成标准化考试 :"已完成標準化考試，時間和精力充足",
        初步确定目标学校:'初步確定目標學校，了解其申請要求',
        无法独立完成文书:'無法獨立完成文書，需要指導與修改',
        希望获得专业指导:'希望獲得專業指導，提高錄取成功率',
        专业咨询老师评价学生背景:'專業諮詢老師評價學生背景，提供選校建議',
        专业文书老师指导创作思路:'專業文書老師指導創作思路，修改申請文書',
        可灵活选择签注指导:'可靈活選擇簽注指導、行前輔導等多項服務',详细的留学规划:'詳細的留學規劃',
        基于企业内部庞大的数据库资料:"基於企業內部龐大的數據庫資料，同時結合學生的學術背景、興趣愛好和未來的發展規劃，目標院校及專業，專業的課程設置等進行最合適的選校定位規劃。",
        全方位的背景提升:'全方位的背景提升',
        根据选校定位为学生匹配相关的背景提升项目和机会:'根據選校定位為學生匹配相關的背景提升項目和機會，包括大廠實習、科研項目、遊學項目等，全方位提升學生的整體競爭力。',
        优质的文书材料:'優質的文書材料',
        文书顾问与学生进行头脑风暴:"文書顧問與學生進行頭腦風暴，一對一溝通，針對不同專業定制化挖掘學生亮點，從招生官角度撰寫個性化高定版的文書材料，拒絕模板化及千篇一律。 ",
        高效的笔试面试特训:'高效的筆試面試特訓',
        HK汇生会拥有丰富的本科:'HK滙生會擁有豐富的本科、碩士、博士筆試面試 輔導經驗，由港澳專業名師指導，提供全真模擬面試及專業詳細的點評。',
        升学后的留学援助:'升學後的留學援助',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:"HK滙生會提供的學生公寓完美解決了學生在港留學的住宿問題，提供的學術輔導服務讓學生能夠順 利完成留學階段的學習任務。",
        学生姓名:'學生姓名',
        申请类型:'申請類型',
        学术成绩:'學術成績',
        英语成绩:'英語成績',
        录取大学:'錄取大學',
        查看更多:'查看更多',
        研究生申请材料:'研究生申請材料',
        个人简历:'1、個人基本材料（身份證等）',
        推荐信:'2、畢業證和學位證/在讀證明',
        学术及实习科研类:'3、成績單',
        个人陈述:'4、雅思/托福/六級成績單',
        本科官方成绩单:'5、工作證明或實習證明',
        语言成绩单:'6、獲獎證書（若有）',
        其他奖项:'7、文書材料（CV/RL/PS）',
        科研:'8、其他',
        托福:'托福',
        有海外英文授课本科背景可免:'（有海外英文授課本科背景可免）',
        一百加:'100+',
        港中科:'港中科',
        九十加:'90+',
        城理浸:'城理浸',
        八十五加:'85+',
        岭南教达:'嶺南教大/其他',
        雅思:'雅思',
        六点五到七:'6.5-7',
        六到六点五:'6-6.5',
        六加:"6+",
        部分专业需要:'部分專業需要',
        具体分数要求:'具體分數要求',
        点击咨询:'點擊諮詢',
        GRE:'GRE/GMAT',
        Advantages:'ADVANTAGES',
        留学优势:'留學優勢',
        Study:'PROGRAMS',
        选择适合你的留学计划:"選擇適合你的留學計劃",
        SERVICESYSTEM:'SERVICE SYSTEM',
        科学智能的服务体系:'科學智能的服務體系，全面提升你的留學戰鬥力',
        准备信息:'準備信息',
        Prepareinformation:'Prepare information',
        Postgraduate:'ENTRANCE REQUIREMENTS',
        研究生申请条件:'研究生申請條件',
        PROCESSKEYPOINTS:'APPLICATION PERIOD',
        研究生申请流程:'研究生申請流程',
        Graduateadmissioncases:"OFFERS",
        研究生录取案例:'研究生錄取案例',

},
overseasstudyDetail:{//香港留学详情
    发布时间:'發佈時間：',
        热度:"熱度：",
        在线咨询:'在線諮詢',
        首页:'首頁',
        港漂干货:'港漂乾貨',
        香港留学:'香港留學',
        PortromptType:"Study in Hong Kong"
},
studentapartment:{//学生公寓
    请输入要搜索的房源:'請輸入要搜索的房源',
        搜索:'搜索',
        区域:'區域',
        学校:'學校',
        租金:'租金',
        租期类型:'租期類型',
        房间类型:'房間類型',
        性别要求:'性别要求',
        公寓列表:'公寓列表',
        月起:'月起',
        不限:'不限',
        以上:'9000以上',
        三千:'0-3000',
        五千:'3000-5000',
        七千:'5000-7000',
        九千:'7000-9000',
},
studentapartmentDetail:{//学生公寓详情
    简介:'簡介',
        周边:'周邊',
        设施:'設施/服務',
        公寓信息:'公寓信息',
        公寓名称:'公寓名稱：',
        附近地铁:'附近地鐵：',
        附近大学:'附近大學：',
        详细地址:'詳細地址：',
        租期类型:'租期類型：',
        房型:'房型：',
        公寓设施:'公寓設施',
        房间设施:'房間設施',
        公寓服务:'公寓服務',
        租房须知:'租房須知',
        预定流程:'預定流程',
        管理服务费:'管理服務費',
        取消预定:'取消預定',
        月:'月'
},
aboutUs:{//关于我们
    致力于提供香港:' 致力於提供香港、澳門、英國、加拿大及外國等國家或地區最專業的留學一站式服務﹕包括副學士、本科、研究生、博士等專業申請、名校直通車計劃、轉學申請、背景提升、學術輔導、科研項目等等。我們擁有第一手香港院校數據，香港學校排名信息，課程信息，以及留學生活指南。旨在成為專業、優質、誠信的香港本土服務品牌，助學生考進心儀院校。 ',
        留学生温暖生活社区:'留學生溫暖生活社區',
        ivingcommunity:'Living community',
        汇生会成立于xxxx年:'滙生會成立於xxxx年， 是香港領先的xx平台。',
        同时我们致力于提供周全的学生生活服务:'同時我們致力於提供周全的學生生活服務﹕包括學生公寓、接送服務、看房驗房、家居保洁、快遞代收等，在香港各大院校周邊提供學生公寓的選擇，為在港留學生提供安全、優質、舒適的生活環境。我們目前已在各大院校周邊運營超過150間學生公寓，其中包括全幢式的物業，累積服務人數過千位學生。 ',
        是香港领先拥有留学:'是香港領先擁有留學+學生公寓服務的一站式平台',
        录取成功率:'錄取成功率為90%',
        公寓累积服务人数过千位学生:'公寓累積服務人數過千位學生',
        超过5年高端留学申请经验:'超過5年高端留學申請經驗',
        专业顾问团队:'專業顧問團隊',
        括名校导师:'(包括名校導師/前招生官擔任顧問)',
        为何需要升学顾问:'為何需要升學顧問',
        Whydo:'Why do I need a counselor',
        省时:'省時、專業',
        到外地留学并不是填好表格送出就完事了:' 到外地留學並不是填好表格送出就完事了，如果沒有考慮自己未來發展，只看到一間學校評價不錯就遞交申請入學，很有可能會浪費你的時間和金錢。 ',
        升学顾问能从每个学校的特点:'升學顧問能從每個學校的特點，專業角度，錄取要求都會有詳細的了解和成功個案數據參照。 ',
        在背景提升方面:' 在背景提升方面，無論是專業課或者英語提升，升學顧問能推荐一些往屆學生體驗效果較好的培訓班',
        往往留学文书材料是学生联系我们的主要原因:'往往留學文書材料是學生聯繫我們的主要原因，寫作是一門藝術，為了讓學生入讀心儀的大學，會幫助學生盡可能發挖自我，讓文書內容更貼切招生官想看到的內容。 ',
        联系我们:'聯絡我們',//修改
        客服热线:'客服熱線',
        微信公众号:'微信公眾號',
        联系地址:'聯繫地址',
        在线留言:'在線留言',
        姓名:'姓名',
        联系方式:'聯繫方式',
        邮箱:'郵箱',
        内容:'内容',
        提交:'提交',
        请填写联系方式:"請填寫聯繫方式",
        提交成功:'提交成功',
        已提交:'已提交',
        一站式留学:'一站式留學',
        OverseasStudy:'Overseas Study',
        contactus:'Contact Us',//修改
},
admission:{//录取捷报
    首页:'首頁',
        录取捷报:'錄取捷報',
        副学士:'副學士',
        本科:'本科',
        研究生:'研究生',
        已提交:'已提交',
        AdmissionsuccessReport:'Admission success Report',
        APPLICATIONPROCESS:"PARTNERS",
        支持:'支持/合作機構',

},
admissionDeatil:{//录取捷报详情
    首页:'首頁',
        录取捷报:'錄取捷報',
        AdmissionsuccessReport:'Success Offers',
        APPLICATIONPROCESS:"PARTNERS",
        支持:'支持/合作機構',
        发布时间:"發佈時間:",
        热度:"熱度：",
        在线咨询:'在線諮詢',
},
hkgoods:{
    首页:'首頁',
        港漂干货:'港漂乾貨',
        APPLICATIONPROCESS:"PARTNERS",
        支持:'支持/合作機構',
        Portdriftdrycargo:'News'
},
Famousschool:{//名校直通车
    HK汇生会的名校直通车计划:'HK滙生會的名校直通車計劃已幫助眾多背景較差的學生順利進入名校就讀。該計劃由港澳教育界資深人士協助全程參與，為學生提供專業優質的教育諮詢服務和升學方案，幫助學生進行背景提升及準備申請材料，後期提供專業的筆試面試輔導培訓及提供相關支援服務，最終使學生順利取得名校錄取結果，同時能實現不成功便全額退費的承諾。 ',
        计划合适人群本科:'計劃合適人群（本科）',
        不想读预科:'1. 不想讀預科、國際大一等橋樑課程',
        预科在读难以通过:'2. 預科在讀難以通過，想直入大一',
        语言成绩不达标:'3. 語言成績不達標',
        计划合适人群研究生:'計劃合適人群（研究生）',
        本科院校背景较差:'1. 本科院校背景較差',
        GPA偏低:'2. GPA偏低',
        软能力不足:'4. 軟能力不足，零實踐經驗',
        国内考研失利后想入读名校:'5. 國內考研失利後想入讀名校',
        详细的留学规划:"詳細的留學規劃",
        基于企业内部庞大的数据库资料:' 基於企業內部龐大的數據庫資料，同時結合學生的學術背景、興趣愛好和未來的發展規劃，目標院校及專業，專業的課程設置等進行最合適的選校定位規劃。',
        全方位的背景提升:'全方位的背景提升',
        根据选校定位为学生匹配相关的背景提升项目和机会:'全方位的背景提升根據選校定位為學生匹配相關的背景提升項目和機會，包括大廠實習、科研項目、遊學項目等，全方位提升學生的整體競爭力。',
        优质的文书材料:'優質的文書材料',
        文书顾问与学生进行头脑风暴:'文書顧問與學生進行頭腦風暴，一對一溝通，針對不同專業定制化挖掘學生亮點，從招生官角度撰寫個性化高定版的文書材料，拒絕模板化及千篇一律。',
        高效的笔试面试特训:'高效的筆試面試特訓',
        HK汇生会拥有丰富的本科:"HK滙生會擁有豐富的本科、碩士、博士筆試面試輔導經驗，由港澳專業名師指導，提供全真模擬面試及專業詳細的點評。",
        升学后的留学援助:'升學後的留學援助',
        HK汇生会提供的学生公寓完美解决了学生在港留学的住宿问题:'HK滙生會提供的學生公寓完美解決了學生在港留學的住宿問題，提供的學術輔導服務讓學生能夠順利完成留學階段的學習任務。',
        香港本土高端留学机:'香港本土高端留學機構',//修改
        年高端申请经验:'6年高端申請服務經驗',//修改
        海量名校录取案例:'海量名校錄取案例',
        服务模式:'5+1服務模式',
        老师服务:'5位老師服務1名學生',
        包括香港本土老师和名校顾问:"包括香港本土老師和名校顧問",
        对申请结果负责:'對申請結果負責',
        录取成功率:'95%錄取成功率',//修改
        录取率:'90%目標名校錄取率',//修改
        一站式留学服务:'升學後的全方位援助服務',
        提供完善的接机:'提供完善的簽證指導、住宿安排、學術輔導、實習求職規劃等服務',
    学生姓名:'學生姓名：',
        申请类型:'申請類型：',
        学术成绩:'學術成績：',
        英语成绩:'英語成績：',
        录取大学:'錄取大學：',
        查看更多:'查看更多',
        Studyabroadprogram:'DIRECT PATH TO DREAM SCHOOL',
        名校直通车计划优势:"名校直通車計劃優勢",
        直通车计划服务流程:'直通車計劃服務流程',
        Study:'Study abroad program',
        ADVANTAGE:'ADVANTAGE',
        选择智优:'HK匯生會，助力學生斬獲夢校錄取',
        录取捷报:"錄取捷報",
        SUCCESSOFFER:'OFFER',
        OverseasStudy:'Overseas Study',
        一站式留学:'一站式留學',
        SERVICESYSTEM:'SERVICE SYSTEM',
        科学智能的服务体系:"科學智能的服務體系，全面提升你的留學戰鬥力",
        选择汇生会:"選擇滙生會,讓香港豐富導師資源為你所用",
        优质的定制化文书材料:'優質的定制化文書材料',
        一站式服务流程:'資深留學服務團隊',//新增
        专业资深导师团队:'專業資深導師團隊',//新增
        包括香港本地教授助阵:'包括香港本地教授助陣',//新增
        海量成功案例:'海量成功案例',//新增
        升学后的全方位援助服务:'升學後的全方位援助服務',//新增
        提供专业的住宿服务:'提供專業的住宿服務、學術輔導及就業指導規劃',//新增
},
poplypop:{
    汇生汇:'滙生會',//修改
        香港:'香港',
        文化科技有限公司:'文化科技有限公司',
        深圳:'深圳',
        姓名:'姓名',
        请输入:'請輸入',
        性别:'性别',
        留学申请:'留學申請',
        微信号:'微信號',
        提交:'提交',
        男:'男',
        女:'女',
        副学士:'副學士',
        本科:'本科',
        研究生:'研究生'
},
poplypoptwo:{
    汇生汇:'滙生會',//修改
        香港:'香港',
        文化科技有限公司:'文化科技有限公司',
        深圳:'深圳',
        姓名:'姓名',
        准备就读院校:'準備就讀院校',
        请输入目前:'請輸入目前/準備就讀院校',
        请选择:'請選擇',
        请输入:'請輸入',
        性别:'性别',
        留学申请:'留學申請',
        微信号:'微信號',
        提交:'提交',
        男:'男',
        女:'女',
        其他:'其他'
},
dialog:{
    汇生汇:'滙生會',//修改
        香港:'香港',
        文化科技有限公司:'文化科技有限公司',
        深圳:'深圳',
        留言内容:'留言内容',
        姓名:'姓名',
        电话:'電話（必填）',
        地址:'地址',
        微信:'微信',
        请输入:'請輸入',
        提交:'提交',
        请填写手机号:'請填寫手機號'
},
erweimaDialog:{
    汇生汇:'滙生會',//修改
        香港:'香港',
        文化科技有限公司:'文化科技有限公司',
        深圳:'深圳',
        联系我们:'聯繫我們',
        了解更多:'了解更多'
},
ielts:{
    评分标准:'評分標準',
        总分:"總分：99分",
        选择模式:'選擇模式',
        考试模式:'考試模式',
        考试内容:'考試内容',
        考试流程:'考試流程',
        报名程序:'報名程序',
        考后服务:'考後服務',
},
toefl:{
    考试形式:'考試形式',
        退考与转考:"退考與轉考",
        成绩复议:'成績复議',
        考试流程:'考試流程',
},
scientificResearch:{
    论文修改:'論文修改 . 潤色發表',
        Paper:'Paper revision — polishing for publication',
        项目流程:'項目流程',
        导师根据学生原有论文提出修改建议:'導師根據學生原有論文提出修改建議，固定1小時講解修改建議，學生自主初步修改後，導師再進行潤色，定稿。 ',
        适合学生:'適合學生',
        适合已有论文的学生:'適合已有論文的學生',
        项目导师:'項目導師',
        双一流名校博士:'雙一流名校博士、博士後、大學講師',
        学术成果:'學術成果',
        综述型论文:'綜述型論文',
        发表级别:'發表級別',
        国际会议论文:'CPCI/EI國際會議論文',
        项目收获:'項目收穫',
        修改完善的论文:'修改完善的論文 論文錄用通知',
        综述型论文辅导:'綜述型論文輔導',
        Literature:'Literature Review Tutoring',
        项目周期:'項目週期',
        包含12课时授课:'12個月，包含12課時授課（參考）+無限次課後答疑',
        适合全部学科:'適合全部學科，學習意願較強的學生',
        理工:"理工、社會科學、商科研究型論文，帶研究模型（或實驗）10版面左右（近萬詞）論文",
        国际会议录用:'CPCI/EI國際會議錄用',
        篇独作的论文:'1篇獨作的論文；導師推薦信',
        录用通知:'SCI錄用通知；完整的科研經歷',
        核心型论文辅导:'核心型論文輔導',
        Core:'Core Thesis Tutoring',
        结果导向:'結果導向，不限次數輔導答疑',
        本科及以上学生:'本科及以上學生、需核心期刊發表經歷',
        代表性案例:'代表性案例·學員故事',
        学习方向:'學習方向：心理學',
        高中学校:'高中學校：深圳耀華實驗學校',
        学生成绩:'學生成績：托福101分，SAT 1480分',
        课题名称:' 課題名稱：探討成本和代價對於人們無私助人行為的影響',
        Matt钟情于心理学专业:'Matt鍾情於心理學專業，目標學校是Reed College。但是在普遍托福110,SAT1500的競爭者中，依舊倍感壓力。 Matt在翻閱學校信息的時候也發現，心理學在後續的學習中需要做大量的研究。於是Matt決定提前做一個相關的科研課題，以此來獲取夢校的青睞。 ',
        在确认了心理学的大方向之后:'在確認了心理學的大方向之後，在導師的建議下，選擇了心理學與經濟學交叉的課題，使得心理學研究有了一個新的落腳點。導師悉心指導，開題，文獻閱讀，採訪、收集數據、整合分析，論文撰寫，這一路走來每一天都會面對不同的桃戰，感受到科研獨特的魅力。終於，論文成功發表到國際CPCI會議期刊，並且受邀參與ICSSH',
        位于京都的会议:'2017 位於京都的會議。科研結果豐碩，Matt更加堅定了要在心理學領域送行深耕的信念。科研論文項目是除成績以外最能證明學術能力的材料，他把這段經歷寫到申請文書當中，表明自己對目標專業的鍾情以及學術潛力，最終版利收穫了Reed',
        College:' College, Macalester College, Lafayette College等13所學校的offer，最終確認入該Reed College學院開啟新的探索之旅。可喜的是,2017年11月，Matt先借高中階段的科研經歷，成為全校難一一個以新生身份進入里德學院Leaming&Adaptive Behav/ior Laboratory試驗室的學生。在2018年12月，Matt in Chicago,並獲得了里德學院頒發的獎學金，用於出席行為分析協會國際會設(ABAI)。 ',
        EI源刊:'SCI JCR3區/EI源刊',
        导师推荐信:'1篇獨作的論文；導師推薦信',
        完整的科研经历:'SCI錄用通知；完整的科研經歷',
        Projectoverview:'OVERVIEW',
        项目概述:"項目概述",
        Productintroduction:'PRODUCTS',
        产品简介:'產品簡介',
        Studentstory:'Student story',
        MattWan:'Matt Wan',

},
interrnshipprogram:{
    strength:'STRENGTHES',
        实力覆盖行业:'實力覆蓋行業',
        Suitableforpeople:'CUSTOMERS',
        适合人群:'適合人群',
        internship:'INTERNSHIP',
        热门实习介绍:'熱門實習介紹',
        合作企业:"合作企業",
        Suitableforpeoples:'PARTNERS',
},
coach:{
    Entered:"OFFER LIST",
        已经进入的名企列表:"已經進入的名企列表",
        服务流程:'服务流程',
        Serviceprocess:'Service process',
        Successstories:'SUCCESSFUL CASES',
        成功案例:'成功案例',
},
paper:{
    Projectprocess:'SERVICES',
        项目流程:'項目流程',
        Thesiscounselingprocess:'GUIDANCE PROCESS',
        论文辅导流程:'論文輔導流程',
        Coachingprocess:'GUIDANCE PROCESS',
        Essay辅导流程:"Essay 輔導流程",
        Servicehighlights:"STRENGTHES",
        服务亮点:'服務亮點',
        服务保障:'服務保障',
        Serviceguarantee:'SERVICE GUARANTEE',
},
tutor:{
    Famousteacherguidance:'PROFESSIONAL GUIDANCE',
        名师辅导:'名師輔導',
        Coachingprocess:'PROCESS',
        辅导流程:'輔導流程',
        Serviceadvantage:'STRENGTHES',
        服务优势:'服務優勢'
},
studyabroad:{
    Servicecontent:'Service content',
        笔试面试服务内容:'筆試面試服務內容',
        Serviceadvantage:'Service advantage',
        服务优势:'服務優勢',
        Serviceprocess:'Service process',
        服务流程:'服務流程',
},
entrance:{
    首页:'首頁 > 香港高校',
        PortromptType:"UNIVERSITIES",
        APPLICATIONPROCESS:'PARTNERS',
        支持:'支持/合作機構',

},
    join:{
        实习岗位:'實習崗位',
        全职岗位:'全職崗位'
    }
};