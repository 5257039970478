<template>
  <div>
    <div class="footerpage">
      <!--≥768px -->
      <div class="footer-con hidden-xs-only">
        <div class="footer-fix-text" :class="screenWidth>=992?'footer-fix':'footer-fix-md'">
          <div class="foot-left" v-if="screenWidth>=872">
            <div class="foot-leftcon">
              <div class="image-logo">
                <img src="@/assets/image/logo.png" alt="">
              </div>
              <div>
                <div class="hsh-xg-sz">
                  <div style="font-size: 20PX">{{$t('footer.汇生会')}}</div>
                  <div>
                    <div>（{{$t('footer.香港')}}）{{$t('footer.文化科技有限公司')}}</div>
                    <div>（{{$t('footer.深圳')}}）{{$t('footer.文化科技有限公司')}}</div>
                  </div>
<!--                  <div>{{$t('footer.文化科技有限公司')}}</div>-->
                </div>
<!--                 <div class="bzhi-rz">xxxxxxxxxxxxxx标志认证</div>-->
              </div>
            </div>
            <div class="backgfff"  @click="joinusClick">{{$t('footer.加入我们')}}</div>
          </div>
          <div class="foot-leftest" v-else>
            <div class="foot-leftcon">
              <div class="image-logo">
                <img src="@/assets/image/logo.png" alt="">
              </div>
              <div>
                <div class="hsh-xg-szest">
                  <div>{{$t('footer.汇生会')}}</div>
                  <div>
                    <div>（{{$t('footer.香港')}}）</div>
                    <div>（{{$t('footer.深圳')}}）</div>
                  </div>
                  <div>{{$t('footer.文化科技有限公司')}}</div>
                </div>
                <!-- <div class="bzhi-rzest">xxxxxxxxxxxxxx标志认证</div> -->
              </div>
            </div>
            <div class="backgfff"  @click="joinusClick">{{$t('footer.加入我们')}}</div>
          </div>
          <div class="phone-text">
            <div class="main-con">
              <div class="content_img">
                <img src="@/assets/image/phone1.png" alt="">
              </div>
              <div>
                <div class="fontsize16" style="margin-bottom: 8PX;font-weight: bold">{{$t('footer.联系电话')}}</div>
                <div class="fontsize14" v-if="customer.hotline" style="margin-bottom: 2PX;">
                 {{customer.hotline||''}}
                </div>
                <div class="fontsize14" v-if="customer.phone">
                 {{customer.phone||''}}
                </div>
              </div>
            </div>
            <div class="main-con margintop16">
              <div class="content_img">
                <img src="@/assets/image/map1.png" alt="">
              </div>
              <div>
                <div class="fontsize16" style="margin-bottom: 8PX;font-weight: bold">{{$t('footer.地址')}}</div>
                <div class="fontsize14">
                 {{customer.address||''}}
                </div>
              </div>
            </div>
          </div>
          <div class="right-main fontsize16">
            <div class="right-con">
              <div>
                <img :src="baseUrl+customer.xiaohongshuUrl" alt="">
              </div>
              <div class="margintop16 ">{{$t('footer.小红书')}}</div>
            </div>
            <div class="right-con">
              <div>
                <img :src="baseUrl+customer.wxPublicUrl" alt="">
              </div>
              <div class="margintop16">{{$t('footer.公众号')}}</div>
            </div>
            <div class="right-con">
              <div>
                <img :src="baseUrl+customer.consultUrl" alt="">
              </div>
              <div class="margintop16">{{$t('footer.咨询号')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden-sm-and-up fooot-xs-ste">
        <div>
          <div class="foot-leftcon-sm">
            <div class="image-logo-sm">
              <img src="@/assets/image/logo.png" alt="">
            </div>
            <div>
              <div class="hsh-xg-sz">
                <div  style="font-size: 20PX">{{$t('footer.汇生会')}}</div>
                <div>
                  <div>（{{$t('footer.香港')}}）</div>
                  <div>（{{$t('footer.深圳')}}）</div>
                </div>
                <div>{{$t('footer.文化科技有限公司')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="phone-text-xs">
          <div class="main-con">
            <div class="content_img">
              <img src="@/assets/image/phone1.png" alt="">
            </div>
            <div>
              <div class="fontsize16">{{$t('footer.联系电话')}}</div>
              <div class="fontsize14" v-if="customer.hotline">
               {{customer.hotline||''}}
              </div>
              <div class="fontsize14"  v-if="customer.phone">
                {{customer.phone||''}}
              </div>
            </div>
          </div>
          <div class="main-con margintop16">
            <div class="content_img">
              <img src="@/assets/image/map1.png" alt="">
            </div>
            <div>
              <div class="fontsize16">{{$t('footer.地址')}}</div>
              <div class="fontsize14">
               {{customer.address||''}}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="bzhi-rz-sm">xxxxxxxxxxxxxx标志认证</div> -->
        <div class="right-main-xs fontsize16">
          <div class="right-con">
            <div>
               <img :src="baseUrl+customer.xiaohongshuUrl" alt="">
            </div>
            <div class="margintop16 ">{{$t('footer.小红书')}}</div>
          </div>
          <div class="right-con">
            <div>
               <img :src="baseUrl+customer.wxPublicUrl" alt="">
            </div>
            <div class="margintop16">{{$t('footer.公众号')}}</div>
          </div>
          <div class="right-con">
            <div>
              <img :src="baseUrl+customer.consultUrl" alt="">
            </div>
            <div class="margintop16">{{$t('footer.咨询号')}}</div>
          </div>
        </div>
        <div class="backgfff" @click="joinusClick">{{$t('footer.加入我们')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";

export default {
  props:{
    customer:{
      type:Object,
      default () {
					return {};
				},
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,//实时宽度
      screenHeight: document.body.clientHeight,//实时高度
      baseUrl:''
    }
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      })();
    };
    window.addEventListener("resize", () => {
      this.update();
    });
    this.baseUrl=this.$store.state.baseUrl
  },
  methods: {
    joinusClick(){
      this.$router.push({
          path: '/joinus',
        });
    },
    update() {
      this.$nextTick(() => {
        this.$forceUpdate()
      });
    },
  }
}
</script>

<style>

.footerpage {
  width: 100%;
  /* position: relative; */
}

.footer-con {
  height: 220PX;
  background: #F2F2F2;
}

.footer-fix {
  width: 1176PX;
  height: 100%;
  margin: 0 auto;
}

.footer-fix-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-fix-md {
  width:1176PX;
  height: 100%;
  margin: 0 auto;
}

.image-logo img {
  width: 74PX;
  height: 74PX;
  margin-right: 24PX;
  border-radius: 8PX;
}
.image-logo-sm img{
  width: 44PX;
  height: 44PX;
  margin-right: 10PX;
  border-radius: 8PX;
}
.backgfff {
  background: #333333;
  height: 40PX;
  font-size: 16PX;
  border-radius: 27PX;
  color: #FFF;
  width: 112PX;
  text-align: center;
  line-height: 40PX;
  margin: 10PX auto;
}

.backgfff:hover {
  cursor: pointer;
}

.foot-left {
   width: 400PX!important;
  height: 220PX;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-top: 30PX;
}

.foot-leftest {
  height: 220PX;
   /*width: 300PX;*/
  font-size: 16px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.hsh-xg-szest {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 44PX;
}

.bzhi-rzest {
  height: 30PX;
  font-size: 16px;
  text-align: left !important;
}

.foot-leftcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hsh-xg-sz {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16PX;
  height: 44PX;
}

.bzhi-rz {
  /*height: 30PX;*/
  margin: 12PX 0 11PX 0;
  font-size: 16PX;
  text-align: left !important;
}

.content_img img {
  width: 24PX;
  height: 24PX;
  margin-right: 15PX;
}

.main-con {
  display: flex;
  flex-direction: row;
  text-align: left;
}

.phone-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-main {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.right-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.right-con img{
  width: 70PX;
  height: 70PX;
}
.right-con > div:first-child {
  /* border: 1PX solid #333; */
  width: 70PX;
  height: 70PX;
  border-radius: 10PX;
}

/* 小屏 */
.fooot-xs-ste {
  width: 100vw;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background: #F2F2F2;
  color: #333;
  padding: 30PX 5vw;
}
.foot-leftcon-sm{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10PX;
}
.phone-text-xs {
  display: flex;
  flex-direction: column;
  padding: 20PX 0;
  border-bottom: 1px solid #999999;
}

.bzhi-rz-sm {
  height: 30PX;
  font-size: 16PX;
  text-align: center !important;
  margin-top: 40PX;
}
.phone-text-xs > div:nth-child(1) {
  margin-right: 10PX;
}

.right-main-xs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 10PX;

}
</style>