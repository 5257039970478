<template>
  <div>
    <div class="ewmimage">
      <img :src="url" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
.ewmimage img {
  position: absolute;
  left: 0;
  top: 80PX;
  z-index: 2100;
  width: 150PX !important;
  height: 150PX !important;
}
</style>