<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created () {
    if( !localStorage.getItem("language") ) {
      localStorage.setItem("language", "zh")
    }
  },
}
</script>

<style>
@import url("./style/commont.css");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
  overflow-y: scroll;
}

* ::-webkit-scrollbar {
  display: none;
}

.content {
  margin-top: 140PX;
  width: 100vw;

}
</style>
