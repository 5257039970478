
/**   
 * api接口统一管理
 */
 import {  get,post } from '@/request/index.js'

 //获取背景提升内容
 export const getJianzhiOne =data => post({
    url:"/website/index/public/getBanner",
   data
})


//获取合作机构
export const agencList =data => get({
   url:"/website/index/public/agencList",
  data
})


//获取客服联系方式
export const customerService =data => get({
   url:"/website/index/public/customerService",
  data
})


//香港留学内容列表
export const hongKongStudyAbroadList =data => post({
   url:"/website/index/public/hongKongStudyAbroadList",
  data
})



//获取地区
export const getAddress =data => post({
   url:"/website/index/public/getAddress",
  data
})


//获取学校
export const getSchool =data => post({
   url:"/website/index/public/getSchool",
  data
})

//香港留学内容详情
export const hongKongStudyAbroadDetils =data => post({
   url:"/website/index/public/hongKongStudyAbroadDetils",
  data
})


//获取学校介绍列表
export const getSchoolPresentList =data => post({
   url:"/website/index/public/getSchoolPresentList",
  data
})


//获取学校介绍详情
export const getSchoolPresentDetils =data => post({
   url:"/website/index/public/getSchoolPresentDetils",
  data
})


//添加浏览数量-香港留学
export const addViewsCountHongKongStudyAbroad =data => post({
   url:"/website/index/public/addViewsCountHongKongStudyAbroad",
  data
})
//增加学校介绍详情浏览量
export const addSchoolPresentViewsCount =data => post({
   url:"/website/index/public/addSchoolPresentViewsCount",
  data
})


//加入我们
export const joinUs =data => post({
   url:"/website/index/public/joinUs",
  data
})


//在线留言提交
export const addMessageRecord =data => post({
   url:"/website/index/public/addMessageRecord",
  data
})


//获取搜索的基础信息
export const getSearchSys =data => post({
   url:"/website/index/public/getSearchSys",
  data
})


//公寓列表
export const lifeRoomList =data => post({
   url:"/website/index/public/lifeRoomList",
  data
})


//房间详情
export const roomDetils =data => post({
   url:"/website/index/public/roomDetils",
  data
})